@import '~theme/typography.module.css';
@import '~theme/viewport.module.css';

.SuccessModalViewingDetails {
  border-radius: var(--space-md);
  display: block;
  padding: 0;
  text-align: center;

  @media (--viewport-lg) {
    box-shadow: var(--shadow-resting);
    margin-bottom: var(--space-lg);
    margin-top: var(--space-lg);
    padding: var(--space-xxl);
  }
}

.SuccessModalViewingDetails-iconContainer {
  height: calc(var(--space-base) * 16); /* 64px */
  margin: 0 auto var(--space-lg);
  mask: url('https://hubble.imgix.net/brand/blob2-white.png?w=64&h=64');
  mask-repeat: no-repeat;
  text-align: left;
  width: calc(var(--space-base) * 16); /* 64px */
}

.SuccessModalViewingDetails-iconContainer--blue-100 {
  background-color: var(--color-blue-100);
}

.SuccessModalViewingDetails-iconContainer--green-200 {
  background-color: var(--color-green-200);
}

.SuccessModalViewingDetails-icon {
  color: var(--color-neutral-900);
  left: 19px;
  position: relative;
  top: 19px;
}

.SuccessModalViewingDetails-title {
  color: var(--color-neutral-900);
  font: var(--font-style-title-1);
}

.SuccessModalViewingDetails-badge {
  display: flex;
  justify-content: center;
  margin-bottom: var(--space-md);
}

.SuccessModalViewingDetails-description {
  font: var(--font-style-content-2);
  margin: 0;

  /* This is hacky, we shouldn't do it, but we do it
  here because it lets us use jsx elements in the data
  content description, which lets us use <strong> tags etc */
  & > span {
    margin: 0;
  }
}

.SuccessModalViewingDetails-buildingName {
  font: var(--font-style-content-2);
  font-weight: var(--font-weight-medium);
}

.SuccessModalViewingDetails-price {
  font: var(--font-style-content-2);
}

.SuccessModalViewingDetails-viewingDateTime {
  composes: u-typographySmallLabel;
  font-size: var(--font-size-lg);
  line-height: var(--font-lineheight-sm);
}
