.VerticalSpacing--base {
  margin-bottom: var(--space-base);
}

.VerticalSpacing--xs {
  margin-bottom: var(--space-xs);
}

.VerticalSpacing--sm {
  margin-bottom: var(--space-sm);
}

.VerticalSpacing--md {
  margin-bottom: var(--space-md);
}

.VerticalSpacing--lg {
  margin-bottom: var(--space-lg);
}

.VerticalSpacing--xl {
  margin-bottom: var(--space-xl);
}

.VerticalSpacing--xxl {
  margin-bottom: var(--space-xxl);
}

.VerticalSpacing--xxxl {
  margin-bottom: var(--space-xxxl);
}
