.TextInput {
  width: 100%;
}

.TextInput-icon {
  margin: 10px 0 0 var(--space-xs);
  position: absolute;
}

.TextInput-icon--withLabel {
  margin: 35px 0 0 var(--space-xs);
  position: absolute;
}

.TextInput-input {
  border: 1px solid var(--color-neutral-500);
  border-radius: var(--space-xs);
  box-shadow: var(--TextInput-boxShadow);
  color: var(--color-neutral-900);
  font: var(--font-style-content-2);
  font-family: var(--font-family-default);
  height: calc(var(--space-base) * 12); /* 48px; */
  padding: calc(var(--space-base) * 3); /* 12px; */
  width: 100%;

  :active,
  :focus {
    border: 1px solid var(--color-neutral-900);
  }

  :disabled {
    background: var(--color-neutral-200);
    color: var(--color-neutral-500);
  }

  ::placeholder {
    color: var(--color-neutral-500);
    opacity: 1; /* Reset for Firefox */
  }

  &[type='date'] {
    background-color: transparent;
  }
}

/**
  * A bit of complex CSS here. InputGroups are basically attached inputs.
  * Here's the gist if how we accomplish this:
  * 1. Inside an input group, we only want to round the left side of the first
  *    element, and the right side of the last element.
  * 2. Inside an input group, we only want 1px borders between elements. One
  *    border - in this case, the left one - should be transparent on all but
  *    the first element.
  * 3. When an element is active/focused, we want all four borders to show.
  *    This avoids a circumstance where an element appears "open" at one side
  *    because of its 3-sided focus border color.
  *
  * Selectors like this - particularly, a component changing its style because
  * it's contained by another component - is generally discouraged. However,
  * we made an exception here, because there is simply no other good way to
  * accomplish this type of style.
  */
:global(.hbl-InputGroup) .TextInput-input {
  border-radius: 0; /* See 1. */
  flex: 1;
}

/**
  * Style the text input, when it is:
  * 1. not focused or active, and
  * 2. not in an error state, and
  * 3. not inside the first child of an .hbl-InputGroup
  */
:global(.hbl-InputGroup) > :first-child .TextInput-input {
  border-bottom-left-radius: var(--space-xs); /* See 1. */
  border-top-left-radius: var(--space-xs); /* See 1. */
}

:global(.hbl-InputGroup) > :last-child .TextInput-input {
  border-bottom-right-radius: var(--space-xs); /* See 1. */
  border-top-right-radius: var(--space-xs); /* See 1. */
}

:global(.hbl-InputGroup)
  > :not(:first-child, :active, :focus)
  .TextInput-input {
  border-left-color: transparent; /* See 2, 3. */
}

.TextInput-input--withIcon {
  padding-left: 30px;
}

.TextInput-input--invalid {
  border: 1px solid var(--color-error);
}

/**
  * Errors should always have a left border, even inside an InputGroup.
  *
  * See inputStyle for more info on what this is doing.
  */
:global(.hbl-InputGroup)
  > *:not(:first-child, :active, :focus)
  .TextInput-input--invalid {
  border-left-color: var(--color-error); /* See 2, 3. */
}

:global(.hbl-InputGroup).TextInput {
  flex: 1;
}
