@import '~theme/viewport.module.css';

.u-hideBelowLg {
  display: none;

  @media (--viewport-lg) {
    display: block;
    height: 100%;
  }
}
