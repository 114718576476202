.ActionText {
  cursor: pointer;
  font-family: var(--font-family);
  padding: 0;
  text-decoration: none;
  transition: color var(--animation-short) ease;
  width: fit-content;
}

.ActionText--content1 {
  font: var(--font-style-content-1);
}

.ActionText--content2 {
  font: var(--font-style-content-2);
}

.ActionText--content3 {
  font: var(--font-style-content-3);
}

.ActionText--title1 {
  font: var(--font-style-title-1);
}

.ActionText--primary {
  color: var(--color-brandprimary);

  &:disabled {
    color: var(--color-neutral-400);
  }

  &:enabled {
    &:hover,
    &:focus {
      color: var(--color-brandprimary-hover);
      text-decoration: underline;
    }

    &:active {
      color: var(--color-brandprimary-active);
      text-decoration: underline;
    }
  }
}

.ActionText--secondary {
  color: var(--color-neutral-900);

  &:disabled {
    color: var(--color-neutral-400);
  }

  &:enabled {
    &:hover,
    &:focus {
      color: var(--color-neutral-800);
      text-decoration: underline;
    }

    &:active {
      color: var(--color-neutral-700);
      text-decoration: underline;
    }
  }
}

.ActionText--cancel {
  color: var(--color-error);

  &:disabled {
    color: var(--color-neutral-400);
  }

  &:enabled {
    &:hover,
    &:focus {
      color: var(--color-error-hover);
      text-decoration: underline;
    }

    &:active {
      color: var(--color-error-active);
      text-decoration: underline;
    }
  }
}

.ActionText--underlined {
  text-decoration: underline;
}
