.TableHeader-detail {
  display: flex;
  gap: var(--space-md);
  margin-bottom: var(--space-sm);
}

.TableHeader-linkContainer {
  align-items: start;
  display: flex;
  flex-direction: column;
}

.TableHeader-imgContainer {
  flex: 0 0 80px;
  height: 80px;
  position: relative;
  width: 80px;
}

.TableHeader-img {
  border-radius: 5px;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
}

.TableHeader-notes {
  color: var(--color-neutral-900);
  font: var(--font-style-content-3);
  font-weight: var(--font-weight-light);
  padding: 0;

  &:not([disabled]):hover {
    color: var(--color-neutral-600);
    cursor: pointer;
    transition: color 0.2s;
  }
}

.TableHeader-notesCount {
  background-color: var(--color-brandsecondary);
  border-radius: 50%;
  color: var(--color-neutral-900);
  display: inline-block;
  font-size: var(--font-size-xxs);
  height: var(--space-lg);
  padding: var(--space-xs);
  text-align: center;
  width: var(--space-lg);

  &.is-disabled {
    background-color: var(--color-neutral-200);
    color: var(--color-neutral-600);
  }
}

.TableHeader-advisor {
  align-items: center;
  display: flex;
  gap: var(--space-sm);
}

.TableHeader-buildingName {
  text-align: left;
}

.TableHeader-imgCount {
  background-color: white;
  border-radius: 5px;
  bottom: var(--space-sm);
  font-size: var(--font-size-xs);
  height: 1.4em;
  line-height: 1.6;
  position: absolute;
  right: var(--space-sm);
  width: 1.4em;
}

.TableHeader-delete {
  margin-left: auto;
}
