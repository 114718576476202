@import '~theme/viewport.module.css';

.Filters {
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: var(--space-md);
  padding: var(--space-md);

  @media (--viewport-md) {
    background-color: var(--color-transparent);
    display: grid;
    grid-template-columns: 150px auto max-content;
    margin: 0;
    padding: 0;
  }

  /* Until the listing page is full width rather than in columns we need this breakpoint */
  @media (--viewport-lg) {
    display: flex;
    flex-direction: column;
  }

  @media (--viewport-xl) {
    display: grid;
  }
}

.Filters--minSizeWrapper > input {
  @media (--viewport-md) {
    height: 100%;
  }
}

.Filters--button {
  align-items: center;
  display: flex;
  justify-content: center;
}

.Filters--displayMetric {
  border-right: 1px solid var(--color-neutral-300);
  margin-right: var(--space-sm);
  padding-right: var(--space-sm);
  text-align: left;
}

.Filters--displayDate {
  margin-right: var(--space-xs);
}

.Filters--occupancy {
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
  height: 100%;

  @media (--viewport-md) {
    flex-direction: row;
  }
}

.Filters--checkboxWrapper {
  @media (--viewport-md) {
    align-items: center;
    background-color: var(--color-white);
    border: 1px solid var(--color-neutral-200);
    border-radius: var(--space-xs);
    display: flex;
    gap: var(--space-md);
    height: 48px; /* Match the height of the Min Size input */
    padding: 0 var(--space-sm);
  }
}

.Filters--checkboxWrapperPartTime {
  padding: 0;

  @media (--viewport-md) {
    padding: 0 0 0 var(--space-sm);
  }
}

.Filters--label {
  color: var(--color-neutral-900);
}

.Filters--dates {
  display: flex;
  gap: var(--space-md);
  margin-top: var(--space-md);

  @media (--viewport-md) {
    gap: var(--space-xs);
    margin: 0;
    padding: 0 var(--space-xs);
  }
}
