@import '~theme/viewport.module.css';

.CalloutGenericAdvisorContent {
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
    gap: var(--space-sm);
  }

  &.isRecommendationListExpanded {
    align-items: center;
    flex-direction: row;
  }
}

.CalloutGenericAdvisorContent-heading {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: var(--space-sm);

  @media (--viewport-md) {
    align-items: start;
    flex-direction: column;
  }

  &.isRecommendationListExpanded {
    align-items: center;
    flex-direction: row;
  }
}

.CalloutGenericAdvisorContent-advisorCta {
  align-items: center;
  display: flex;
  flex-direction: row;

  @media (--viewport-md) {
    align-items: start;
    flex-direction: column;
  }

  &.isRecommendationListExpanded {
    align-items: center;
    flex-direction: row;
  }
}

.CalloutGenericAdvisorContent-imageWrapper {
  text-align: right;
}

.CalloutGenericAdvisorContent-headingParagraph {
  display: none;

  @media (--viewport-md) {
    display: block;
  }
}
