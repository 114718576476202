.TextArea {
  border: 1px solid var(--color-neutral-500);
  border-radius: var(--space-base);
  color: var(--color-neutral-900);
  display: inline-block;
  font: var(--font-style-content-2);
  font-family: var(--font-family-default);
  height: var(--TextArea--height);
  max-height: var(--TextArea--maxHeight);
  max-width: var(--TextArea--maxWidth);
  min-height: calc(var(--space-base) * 20); /* 80px; */
  padding: calc(var(--space-md) - 1px); /* subtract border-width */
  resize: vertical;
  width: 100%;

  :focus,
  :active {
    border: 1px solid var(--color-neutral-900);
  }

  :disabled {
    background: var(--color-neutral-200);
    color: var(--color-neutral-500);
  }

  ::placeholder {
    color: var(--color-neutral-500);
    opacity: 1; /* reset for Firefox */
  }
}

.TextArea--invalid {
  border: 1px solid var(--color-error);
}
