@import '~theme/viewport.module.css';

.SignupSideContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--space-lg);
}

.SignupSideContent-list {
  display: flex;
  flex-direction: column;
  gap: var(--space-md);
}

.SignupSideContent-listItemText {
  font: var(--font-style-content-1);
  margin: 0;
}
