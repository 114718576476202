@import '~theme/viewport.module.css';

.CheckboxList {
  display: grid;
  list-style: none;
  margin: 0;
  padding-left: 0;
  text-align: left;

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
