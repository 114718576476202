@import '~theme/viewport.module.css';

.Success {
  display: flex;
  flex-direction: column;
}

.Success-heading {
  display: flex;
  justify-content: center;
  position: relative;
}

.Success-close {
  position: absolute;
  right: 0;
  top: 0;
}

.Success-advisor {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.Success-building {
  border-bottom: 1px solid var(--color-neutral-200);
  padding: var(--space-md) 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}
