:root {
  --grid-columnTotal: 12;
  --grid-gutterTotal: 13; /* 1 gutter on each side, 11 gutters between columns */
  --grid-gutterWidth: 16px;
  --grid-columnWidth: 80px;
  --grid-maxWidth: calc(
    calc(var(--grid-columnWidth) * var(--grid-columnTotal)) +
      calc(var(--grid-gutterWidth) * var(--grid-gutterTotal))
  ); /* 1168 */
}
