.ImageCarouselItemCard-wrapper {
  display: none;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
}

.ImageCarouselItemCard-wrapperStyleSelected {
  display: block;
}
