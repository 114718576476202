.Accordion-summary {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  list-style: none;
  width: 100%;

  &::-webkit-details-marker {
    display: none;
  }

  .Accordion &::after {
    content: 'add';
    font-family: var(--font-family-icons);
    font-size: var(--font-size-lg);
    font-style: normal;
    font-weight: normal;
    line-height: 1;
  }

  .Accordion[open] &::after {
    content: 'remove';
  }
}

.Accordion--underlineQuestion .Accordion-summary {
  border-bottom: 1px solid var(--color-neutral-100);
  padding-bottom: var(--space-md);
}
