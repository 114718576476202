@import '~theme/viewport.module.css';

.BookingOverview-contentWrapper {
  border: 1px solid var(--color-neutral-50);
  border-radius: var(--space-md);
  box-shadow: var(--shadow-resting);
  margin-top: var(--space-xl);
  padding: var(--space-md);

  @media (--viewport-md) {
    padding: var(--space-lg);
  }
}
