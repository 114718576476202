@import '~theme/viewport.module.css';

@keyframes pop-in {
  0% {
    opacity: 1;
    transform: scale(1);
    z-index: 2;
  }

  40% {
    transform: scale(1.5);
  }

  80% {
    opacity: 1;
    z-index: 2;
  }

  100% {
    opacity: 0;
    transform: scale(1);
    z-index: 0;
  }
}

.SiteNavigation-animateIcon {
  animation: pop-in 0.5s ease-in-out;
  color: var(--color-brandprimary);
  opacity: 0;
  z-index: 0;
}

.SiteNavigation-accessoryContainer {
  align-items: center;
  display: flex;
  gap: var(--space-sm);
}

.SiteNavigation-iconContainer {
  height: 24px;
  position: relative;
  width: 24px;
}

.SiteNavigation-icon {
  display: block;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}

.SiteNavigation-buttonColor {
  color: var(--color-white);
}

.SiteNavigation-generalEnquiryWrapper {
  @supports (-moz-appearance: none) {
    /*
     * Android firefox puts the address bar at the bottom of
     * the viewport, the below padding prevents it from
     * covering anything
     */
    padding-bottom: var(--space-xxxl);
  }
  @media (--viewport-lg) {
    width: 710px;
  }
}

.SiteNavigation-visibleBelowLgViewport {
  display: block;
  margin-left: auto;
  padding: 0 var(--space-sm);

  @media (--viewport-lg) {
    display: none;
  }
}

.u-alignCenter {
  @media (--viewport-lg) {
    align-items: center;
    display: flex;
  }
}

.SiteNavigation-visibleAboveLgViewport {
  display: none;

  @media (--viewport-lg) {
    display: flex;
  }
}

.SiteNavigation-getAQuote {
  color: var(--color-brandprimary);

  &:focus,
  &:hover,
  &:active {
    color: var(--color-brandprimary-hover);
    transition: color 0.3s ease;
  }
}
