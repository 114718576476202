@import '~theme/viewport.module.css';

.ContentWithBlob {
  align-items: center;
  background-image: var(--ContentWithBlob--backgroundImage);
  background-position: center;
  background-repeat: no-repeat;
  background-size: calc(var(--space-base) * 70);
  color: var(--ContentWithBlob--color);
  display: flex;
  flex-direction: column;
  height: calc(var(--space-base) * 70);
  justify-content: center;
  margin: 0;
  text-align: center;
  width: calc(
    100vw - calc(var(--space-md) * 2)
  ); /* Used to ensure that at mobile the div is wide enough to show the whole blob */

  @media (--viewport-md) {
    margin-top: var(--ContentWithBlob--stagger);
    width: calc(var(--space-base) * 70);
  }
}

.ContentWithBlob-blobLabel {
  display: block;
  font: var(--font-style-content-1);
  margin-bottom: var(--space-md);
  margin-top: var(--space-md);
  max-width: calc(var(--space-base) * 70); /* 280px */
}
