@import '~theme/viewport.module.css';

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.CookieBanner {
  align-items: center;
  animation: fade-in 1s ease;
  background-color: rgb(var(--color-white-rgb) / 80%);
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: var(--z-index-cookiebanner);
}

.CookieBanner-banner {
  background-color: var(--color-neutral-900);
  color: var(--color-white);
  overflow-x: hidden;
  overflow-y: auto;
  padding: var(--space-lg) var(--space-md);
  position: relative;
  text-decoration: none;

  .CookieBanner:not(.CookieBanner--fullScreenAtMobile) & {
    border-radius: 16px;
    margin: var(--space-md);
    max-height: calc(100vh - calc(var(--space-md) * 2));

    @media (--viewport-md) {
      max-height: 85%;
      max-width: 900px;
    }
  }

  .CookieBanner.CookieBanner--fullScreenAtMobile & {
    height: 100vh;
    width: 100vw;

    @media (--viewport-md) {
      border-radius: 16px;
      height: auto;
      margin: var(--space-md);
      max-height: 85%;
      max-width: 900px;
      min-height: 480px;
      width: 100%;
    }
  }
}

.CookieBanner-defaultScreen {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;

  @media (--viewport-md) {
    max-height: 436px;
    max-width: 480px;
    padding: var(--space-xl) 0;
  }
}

.CookieBanner-highlightedText {
  font: var(--font-style-title-1);
  @media (--viewport-md) {
    font: var(--font-style-hero-2);
    max-width: 90%;
  }
}

.CookieBanner-actionsWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.CookieBanner-buttonWrapper {
  margin-left: var(--space-md);
}

.CookieBanner-link {
  color: var(--color-white);
  cursor: pointer;
  flex-shrink: 0;
  font: var(--font-style-content-3);
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

.CookieBanner-preferencesScreen {
  @media (--viewport-md) {
    padding: var(--space-md) var(--space-lg) var(--space-lg);
  }
}

.CookieBanner-closePreferencesWrapper {
  text-align: right;
}

.CookieBanner-loading {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
