@import '~theme/viewport.module.css';

.ImageCarouselItem {
  display: none;
  height: 100%;
  margin-bottom: 0;
  position: relative;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  &.is-selected {
    display: flex;
  }

  &.is-captioned {
    height: 78%;
  }
}

.ImageCarouselItem-figure {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  margin: 0;
  width: 100%;
}

.ImageCarouselItem-image {
  display: none;

  @supports (object-fit: cover) {
    /* See fallback styles for non-supporting browsers below. */
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.ImageCarouselItem-caption {
  bottom: -36px;
  color: var(--color-neutral-900);
  left: 50%;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: auto;

  @media (--viewport-md) {
    bottom: -28px;
  }
}
