@import '~theme/viewport.module.css';

.Grid {
  display: grid;
  grid-template-columns: 1fr;
}

.Grid--xs {
  @media (--viewport-xs) {
    grid-template-columns: repeat(var(--grid-columnTotal), 1fr);
  }
}

.Grid--sm {
  @media (--viewport-sm) {
    grid-template-columns: repeat(var(--grid-columnTotal), 1fr);
  }
}

.Grid--md {
  @media (--viewport-md) {
    grid-template-columns: repeat(var(--grid-columnTotal), 1fr);
  }
}

.Grid--lg {
  @media (--viewport-lg) {
    grid-template-columns: repeat(var(--grid-columnTotal), 1fr);
  }
}

.Grid--xl {
  @media (--viewport-xl) {
    grid-template-columns: repeat(var(--grid-columnTotal), 1fr);
  }
}

.Grid--xxl {
  @media (--viewport-xxl) {
    grid-template-columns: repeat(var(--grid-columnTotal), 1fr);
  }
}

.Grid--xxxl {
  @media (--viewport-xxxl) {
    grid-template-columns: repeat(var(--grid-columnTotal), 1fr);
  }
}

.Grid--gap {
  grid-gap: var(--grid-gap-y) var(--grid-gap-x);
}

.GridItem {
  align-self: var(--GridItem-align);
  justify-self: var(--GridItem-justify);
  margin-bottom: var(--space-lg);
  min-width: 0;

  &:last-child {
    margin-bottom: 0;
  }

  @supports (display: grid) {
    margin-bottom: 0;
  }
}

.GridItem--xs {
  order: var(--GridItem-order);

  @media (--viewport-xs) {
    grid-column-end: var(--GridItem-columnEnd);
    grid-column-start: var(--GridItem-columnStart);
    grid-row-end: var(--GridItem-rowEnd);
    grid-row-start: var(--GridItem-rowStart);
    order: initial;
  }
}

.GridItem--sm {
  order: var(--GridItem-order);

  @media (--viewport-sm) {
    grid-column-end: var(--GridItem-columnEnd);
    grid-column-start: var(--GridItem-columnStart);
    grid-row-end: var(--GridItem-rowEnd);
    grid-row-start: var(--GridItem-rowStart);
    order: initial;
  }
}

.GridItem--md {
  order: var(--GridItem-order);

  @media (--viewport-md) {
    grid-column-end: var(--GridItem-columnEnd);
    grid-column-start: var(--GridItem-columnStart);
    grid-row-end: var(--GridItem-rowEnd);
    grid-row-start: var(--GridItem-rowStart);
    order: initial;
  }
}

.GridItem--lg {
  order: var(--GridItem-order);

  @media (--viewport-lg) {
    grid-column-end: var(--GridItem-columnEnd);
    grid-column-start: var(--GridItem-columnStart);
    grid-row-end: var(--GridItem-rowEnd);
    grid-row-start: var(--GridItem-rowStart);
    order: initial;
  }
}

.GridItem--xl {
  order: var(--GridItem-order);

  @media (--viewport-xl) {
    grid-column-end: var(--GridItem-columnEnd);
    grid-column-start: var(--GridItem-columnStart);
    grid-row-end: var(--GridItem-rowEnd);
    grid-row-start: var(--GridItem-rowStart);
    order: initial;
  }
}

.GridItem--xxl {
  order: var(--GridItem-order);

  @media (--viewport-xxl) {
    grid-column-end: var(--GridItem-columnEnd);
    grid-column-start: var(--GridItem-columnStart);
    grid-row-end: var(--GridItem-rowEnd);
    grid-row-start: var(--GridItem-rowStart);
    order: initial;
  }
}

.GridItem--xxxl {
  order: var(--GridItem-order);

  @media (--viewport-xxxl) {
    grid-column-end: var(--GridItem-columnEnd);
    grid-column-start: var(--GridItem-columnStart);
    grid-row-end: var(--GridItem-rowEnd);
    grid-row-start: var(--GridItem-rowStart);
    order: initial;
  }
}

.GridItem--minWidth {
  min-width: var(--GridItem--minWidth);
}
