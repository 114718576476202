@import '~theme/viewport.module.css';

.Callout {
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);

  &.isRecommendationListExpanded {
    flex-direction: column;
    @media (--viewport-lg) {
      align-items: center;
      flex-direction: row;
    }
  }
}

.Callout-heading {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: var(--space-sm);
  justify-content: space-between;

  @media (--viewport-md) {
    align-items: start;
    flex-direction: column;
  }

  @media (--viewport-lg) {
    align-items: center;
    flex-direction: row;
  }

  &.isRecommendationListExpanded {
    align-items: center;
    flex-direction: row;
  }
}

.Callout-advisorImage {
  bottom: 60px;
  position: absolute;
  right: var(--space-sm);
  z-index: calc(var(--z-index-nav) + 1);

  @media (--viewport-md) {
    position: static;
  }
}

.Callout-advisorCta {
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
  justify-content: space-between;

  @media (--viewport-lg) {
    align-items: center;
    flex-direction: row;
  }

  &.isRecommendationListExpanded {
    align-items: center;
    flex-direction: row;
  }
}

.Callout-advisorDetails {
  display: flex;
  flex-direction: row;
  gap: var(--space-sm);
}

.Callout-linkedin {
  align-items: center;
  display: flex;
  font: var(--font-style-content-3);
  gap: var(--space-xs);
  text-align: center;
}

.Callout-advisorImageContent {
  position: relative;
}

.Callout-icon {
  position: absolute;
  right: -4px;

  @media (--viewport-md) {
    display: none;
    position: static;
  }
}

.u-hideBelowMd {
  display: none;

  @media (--viewport-md) {
    display: block;
  }
}

.u-hideAboveMd {
  @media (--viewport-md) {
    display: none;
  }
}
