@import '~theme/viewport.module.css';

.ViewingRequestOverview {
  padding: var(--space-md);

  @media (--viewport-md) {
    padding: var(--space-lg);
  }
}

.ViewingRequestOverview-headingImageWrapper {
  align-items: flex-start;
  border-bottom: 1px solid var(--color-neutral-100);
  display: flex;
  flex-direction: row-reverse;
  gap: 0 var(--space-md);
  justify-content: flex-end;
  margin-bottom: var(--space-md);
  padding-bottom: var(--space-md);

  @media (--viewport-md) {
    border-bottom: 0;
    flex-direction: column;
    padding-bottom: 0;
  }
}

.ViewingRequestOverview-buildingImageWrapper {
  @media (--viewport-md) {
    margin-top: var(--space-md);
    width: 100%;
  }
}

.ViewingRequestOverview-buildingImageSmall,
.ViewingRequestOverview-buildingImageLarge {
  border-radius: var(--space-sm);
  display: block;
}

.ViewingRequestOverview-buildingImageSmall {
  @media (--viewport-md) {
    display: none;
  }
}

.ViewingRequestOverview-buildingImageLarge {
  display: none;

  @media (--viewport-md) {
    display: block;
  }
}

.ViewingRequestOverview-pricePlan {
  align-items: center;
  display: flex;
  margin-bottom: var(--space-sm);
}

.ViewingRequestOverview-pricePlanDot {
  background: var(--color-neutral-900);
  clip-path: circle();
  height: var(--space-sm);
  margin: 0 var(--space-md);
  shape-outside: circle();
  width: var(--space-sm);
}

.ViewingRequestOverview-price {
  display: flex;
  justify-content: space-between;
}

.ViewingRequestOverview-priceVAT {
  font: var(--font-style-content-3);
}

.ViewingRequestOverviewAccessType {
  align-items: center;
  display: block;
  gap: 0 var(--space-xs);
}

.ViewingRequestOverviewAccessType-day {
  background-color: var(--color-neutral-900);
  border-radius: var(--space-xs);
  color: var(--color-white);
  font: var(--font-style-content-3);
  font-weight: var(--font-weight-medium);
  line-height: 1;
  padding: var(--space-xs);
  text-decoration: none;
  text-transform: capitalize;
}
