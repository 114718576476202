@import '~theme/viewport.module.css';

.HubbleLogo {
  color: var(--HubbleLogo-color);
}

.u-hideBelowSm {
  display: none;

  @media (--viewport-sm) {
    display: block;
  }
}

.u-hideAboveSm {
  @media (--viewport-sm) {
    display: none;
  }
}
