/**
 * Standardised page-level z-index values
 *
 * These are **page-level** z-index values. They should only be used when you
 * are working within the stacking context of a page. If you are working within
 * a component that creates its own stacking context, you can use these
 * variables to set the layer of that stacking context, but any children of the
 * new stacking context should use localised variables.
 */

:root {
  --z-index-normal: 0;

  /* Raised elements. For in-page content that needs an upward nudge. */
  --z-index-raised: 1;

  /* Popovers, like tooltips or filters. Appears above other in-page content. */
  --z-index-popover: 10;

  /* The nav bar. Appears over all other in-page content, but under modals. */
  --z-index-nav: 20;

  /* Drawers. Very similar to modals. Ideally, would never be shown at the same
   * time as a modal, but if they are, the modal should be on top. */
  --z-index-drawer: 30;

  /* Modals, which occupy the whole screen and should obscure any other
  * non-urgent content. */
  --z-index-modal: 40;

  /* Cookie banner. Takes precedence over everything except toasts, because it
   * requires user interaction before continuing. */
  --z-index-cookiebanner: 50;

  /* Toasts always appear on top, because they may show crucial notifications
   * that the user needs to see, even when something like the cookie banner is
   * visible. */
  --z-index-toast: 60;
}
