@keyframes spinner-circle-animation {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.Spinner {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  width: calc(var(--space-base) * 18);
}

.Spinner-circle1,
.Spinner-circle2,
.Spinner-circle3 {
  animation: spinner-circle-animation 1.4s infinite ease-in-out both;
  background-color: currentcolor;
  border-radius: 100%;
}

.Spinner-circle1 {
  animation-delay: -0.32s;
}

.Spinner-circle2 {
  animation-delay: -0.16s;
}

.Spinner--normal > .Spinner-circle1,
.Spinner--normal > .Spinner-circle2,
.Spinner--normal > .Spinner-circle3 {
  height: var(--space-md);
  width: var(--space-md);
}

.Spinner--small > .Spinner-circle1,
.Spinner--small > .Spinner-circle2,
.Spinner--small > .Spinner-circle3 {
  height: calc(var(--space-base) * 3);
  width: calc(var(--space-base) * 3);
}
