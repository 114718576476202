@import '~theme/viewport.module.css';

.ResourcesMenuContent-linkList {
  display: flex;
  flex-direction: column;
  gap: var(--space-md);

  @media (--viewport-sm) {
    column-count: 2;
    display: block;
    gap: 0;

    & > * {
      /*
       * Margin feels more intuitive here. But with multi-column layout,
       * if we use margin, the space will sometimes flow over into the next
       * column, causing misalignment. By using padding instead, plus
       * break-inside: avoid, the padding will stay within the child element.
       */
      break-inside: avoid;
      padding-bottom: var(--space-md);
    }
  }
}

.ResourcesMenuContent-ruler {
  border-bottom: 1px solid var(--color-neutral-100);
  padding-bottom: var(--space-sm);
}
