@import '~theme/viewport.module.css';

.BookingButton-buttonContainer {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  grid-area: button;
  justify-content: center;

  @media (--viewport-md) {
    flex: 0 1 auto;
  }
}

.BookingButton-adminLabel {
  color: var(--color-neutral-700);
}
