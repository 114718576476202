@import '~theme/viewport.module.css';

.BookThisSpace-wrapper {
  display: flex;
  flex-direction: column;
  padding: calc(var(--space-base) * 3) var(--space-md);
  width: 100%;

  @media (--viewport-lg) {
    display: block;
    padding: 0;
  }
}

.BookThisSpace-header {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.BookThisSpace-desktopOnly {
  display: none;
  @media (--viewport-lg) {
    display: block;
  }
}
