@import '~theme/viewport.module.css';

.Location {
  display: grid;
  grid-column-gap: var(--space-xxl);
  grid-row-gap: var(--space-lg);
  grid-template-areas: 'heading' 'mini-map' 'transit-stops';
  @media (--viewport-md) {
    grid-column-gap: var(--space-xxl);
    grid-row-gap: var(--space-lg);
    grid-template-areas: 'heading heading' 'mini-map transit-stops';
    grid-template-columns: auto 1fr;
  }
}

.Location-list {
  display: flex;
  flex-wrap: wrap;
  grid-area: transit-stops;
  list-style: none;
  margin: 0;
  padding: 0;
}

.Location-heading {
  grid-area: heading;
}

.Location-miniMap {
  grid-area: mini-map;
}
