@import '~theme/viewport.module.css';

.HybridSignupContent-contentWrapper {
  align-items: start;
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    padding: 0 var(--space-md);
  }
}

.HybridSignupContent-heading {
  align-self: start;
}

.HybridSignupContent-list {
  list-style: none;
  padding: 0;
}
