.Markup {
  font: var(--font-style-content-1);

  & span[style='font-weight:400;'] {
    font-weight: inherit !important;
  }

  & strong > h2,
  & strong > h3,
  & h3 > strong,
  & h2 > strong {
    font-weight: var(--font-weight-medium);
  }

  & img,
  & div {
    height: auto;
    max-width: 100%;
  }

  & li {
    margin-bottom: 0.5em;
  }

  /* Keep videos from punching out of their container */
  & iframe {
    border: none;
    width: 100%;
  }

  /*
   * Videos are frequently wrapped in a <figure> by WordPress - keep these from
   * indenting.
   */
  & figure {
    margin-left: 0;
    margin-right: 0;
  }
}
