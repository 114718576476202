@import '~theme/viewport.module.css';

.PageNavigation {
  background: var(--color-white);
  border-bottom: 1px solid var(--color-neutral-100);
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  position: sticky;
  top: 0;
  white-space: nowrap;
  width: 100%;
  z-index: 1; /* raise stacking context */
}

.PageNavigation-nav {
  display: flex;
  margin: 0 auto;
}

.PageNavigation-nav.isWidthConstrained {
  max-width: var(--grid-maxWidth);
}

.PageNavigation-items {
  display: flex;
  flex: 1;
  list-style: none;
  margin: 0;
  padding: 0;
}

.PageNavigation-accessory {
  display: none;

  @media (--viewport-md) {
    display: flex;
    flex: 0;
    padding: calc(var(--space-base) * 3) 0;

    > &:last-child {
      margin-right: 16px;
    }
  }
}
