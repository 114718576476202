@import '~theme/typography.module.css';

.OfficeCardTotalCost {
  align-items: flex-end;
  display: flex;
  flex-direction: column;

  @media (--viewport-lg) {
    align-items: baseline;
    flex-direction: row;
  }
}
