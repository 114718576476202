@import '~theme/viewport.module.css';

.ShareButton {
  color: var(--color-neutral-900);
  font: var(--font-style-content-3);
}

.u-displayAboveMd {
  display: none;

  @media (--viewport-md) {
    display: block;
  }
}
