@import '~theme/viewport.module.css';

.AreaItem-list {
  column-count: 2;
  column-fill: balance;
  column-gap: var(--space-md);

  @media (--viewport-md) {
    column-count: initial;

    & > * {
      margin-bottom: var(--space-xs);
    }
  }
}

.AreaItem-link {
  font-size: var(--font-size-xs);
}
