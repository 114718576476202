.Asset--fill {
  width: 100%;
}

.Asset--fillToNatural {
  max-width: 100%;
}

.Asset--fixed {
  height: var(--Asset-height);
  width: var(--Asset-width);
}
