@import '~theme/viewport.module.css';

.NavigationLogo {
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  margin-right: var(--space-sm);
}

.NavigationLogo--hideOnDesktop {
  @media (--viewport-sm) {
    display: none;
  }
}

.NavigationLogo--hideOnMobile {
  display: none;
  @media (--viewport-sm) {
    display: block;
  }
}

.NavigationLogo-logo {
  display: block;
}
