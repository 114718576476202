@import '~theme/viewport.module.css';

/* Fade-in, so that the modal doesn't feel too jarring as it renders. */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Gives the modal a bit of a "bounce-in" effect, instead of appearing from nowhere. */
@keyframes shift-up {
  0% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0);
  }
}

.Modal {
  background-color: var(--color-white);
  border: none;
  border-radius: 0;
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  top: 0;
  width: 100%;
}

.Modal--fullScreen {
  border-radius: 0;
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  top: 0;
  width: 100%;
}

.Modal--popOut {
  &[open] {
    animation:
      fade-in 250ms ease,
      shift-up 250ms ease;
  }

  @media (--viewport-md) {
    border-radius: var(--space-md);
    box-shadow: var(--shadow-raised);

    /**
     * The previous implementation of Modal had a fixed height and width.
     * This was too restrictive for the design team who would occassionaly
     * want to break out of that pattern.
     *
     * This new Modal puts the responsibility for setting the dimensions of
     * the Modal onto the implementer, with the exception that we add a few
     * maximum boundaries to prevent the Modal from expanding into "gutter
     * space".
     */
    height: fit-content;
    max-height: 85%;
    max-width: 900px;
    width: fit-content;

    &::backdrop {
      animation: fade-in 250ms ease;
      background-color: rgb(var(--color-neutral-900-rgb) / 30%);
      overflow: hidden;
    }
  }
}
