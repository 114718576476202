@import '~theme/viewport.module.css';

.MembershipAcceptContent-contentWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    padding: 0 var(--space-md);
  }
}

.MembershipAcceptContent-heading {
  align-self: start;
}

.MembershipAcceptContent-list {
  list-style: none;
  padding: 0;
}

.MembershipAcceptContent-logos {
  align-items: center;
  display: grid;
  gap: var(--space-base) var(--space-xl);
  grid-auto-rows: calc(var(--space-base) * 24); /* 96px */
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  list-style: none;
  margin: 0;
  padding-left: 0;
  text-align: left;

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
