.OfficeCardRecommendationListButton {
  grid-area: recommendation-list-button;
}

.OfficeCardRecommendationListButton--add {
  background-color: var(--color-green-200);
  border-color: var(--color-green-200);

  &:focus,
  &.sb__focus,
  &:hover,
  &.sb__hover,
  &:active,
  &.sb__active {
    background-color: var(--color-green-400);
    border-color: var(--color-green-400);
    color: var(--color-white);
  }

  &:disabled {
    background-color: var(--color-green-100);
    border-color: var(--color-green-100);
    color: var(--button-secondary-color-disabled);
    cursor: var(--button-secondary-cursor-disabled);
  }
}

.OfficeCardRecommendationListButton--remove {
  background-color: var(--color-white);
  border-color: var(--color-green-200);

  &:focus,
  &.sb__focus,
  &:hover,
  &.sb__hover,
  &:active,
  &.sb__active {
    background-color: var(--color-green-600);
    color: var(--color-white);
  }

  &:disabled {
    background-color: var(--button-secondary-bg-disabled);
    border-color: var(--button-secondary-border-color-disabled);
    color: var(--button-secondary-color-disabled);
    cursor: var(--button-secondary-cursor-disabled);
  }
}
