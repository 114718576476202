@import '~theme/viewport.module.css';

.Tour360ContentPopup-iframe {
  height: 400px;
  width: 300px;

  @media (--viewport-sm) {
    height: 400px;
    width: 800px;
  }
}

.Tour360ContentPopup-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
}
