@import '~theme/viewport.module.css';

.Surface {
  background-color: var(--Surface-defaultColor);
  background-image: var(--Surface-defaultColor);
}

.Surface--textOnDark {
  color: var(--color-white);

  @media (resolution >= 2dppx) {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
}

/* sm modifiers */

.Surface--smColor {
  @media (--viewport-sm) {
    background-color: var(--Surface-smColor);
    background-image: var(--Surface-smColor);
  }
}

.Surface--textOnDarkSm {
  @media (--viewport-sm) {
    color: var(--color-white);

    @media (resolution >= 2dppx) {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    }
  }
}

.Surface--textOnLightSm {
  @media (--viewport-sm) {
    color: var(--color-neutral-900);
  }
}

/* md modifiers */

.Surface--mdColor {
  @media (--viewport-md) {
    background-color: var(--Surface-mdColor);
    background-image: var(--Surface-mdColor);
  }
}

.Surface--textOnDarkMd {
  @media (--viewport-md) {
    color: var(--color-white);

    @media (resolution >= 2dppx) {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    }
  }
}

.Surface--textOnLightMd {
  @media (--viewport-md) {
    color: var(--color-neutral-900);
  }
}

/* lg modifiers */

.Surface--lgColor {
  @media (--viewport-lg) {
    background-color: var(--Surface-lgColor);
    background-image: var(--Surface-lgColor);
  }
}

.Surface--textOnDarkLg {
  @media (--viewport-lg) {
    color: var(--color-white);

    @media (resolution >= 2dppx) {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    }
  }
}

.Surface--textOnLightLg {
  @media (--viewport-lg) {
    color: var(--color-neutral-900);
  }
}

/* xl modifiers */

.Surface--xlColor {
  @media (--viewport-xl) {
    background-color: var(--Surface-xlColor);
    background-image: var(--Surface-xlColor);
  }
}

.Surface--textOnDarkXl {
  @media (--viewport-xl) {
    color: var(--color-white);

    @media (resolution >= 2dppx) {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    }
  }
}

.Surface--textOnLightXl {
  @media (--viewport-xl) {
    color: var(--color-neutral-900);
  }
}

/* xxl modifiers */

.Surface--xxlColor {
  @media (--viewport-xxl) {
    background-color: var(--Surface-xxlColor);
    background-image: var(--Surface-xxlColor);
  }
}

.Surface--textOnDarkXxl {
  @media (--viewport-xxl) {
    color: var(--color-white);

    @media (resolution >= 2dppx) {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    }
  }
}

.Surface--textOnLightXxl {
  @media (--viewport-xxl) {
    color: var(--color-neutral-900);
  }
}

/* xxxl modifiers */

.Surface--xxxlColor {
  @media (--viewport-xxxl) {
    background-color: var(--Surface-xxxlColor);
    background-image: var(--Surface-xxxlColor);
  }
}

.Surface--textOnDarkXxxl {
  @media (--viewport-xxxl) {
    color: var(--color-white);

    @media (resolution >= 2dppx) {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    }
  }
}

.Surface--textOnLightXxxl {
  @media (--viewport-xxxl) {
    color: var(--color-neutral-900);
  }
}
