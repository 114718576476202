@import '~theme/viewport.module.css';

.BookingSummaryCard-venueImageWrapper {
  align-items: center;
  display: flex;
  height: fit-content;
  justify-content: center;
  margin-bottom: var(--space-md);

  @media (--viewport-md) {
    margin-bottom: 0;
    margin-right: var(--space-lg);
  }
}

.BookingSummaryCard-venueImage {
  background-color: var(--color-neutral-50);
  border-radius: var(--space-sm);
  object-fit: cover;
  width: 100%;

  @media (--viewport-md) {
    aspect-ratio: 1;
    width: 115px;
  }
}

.BookingSummaryCard-bookingDescriptionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.BookingSummaryCard-buildingIcon {
  color: var(--color-neutral-100);
  font-size: 80px;
  text-align: center;
  width: 115px;
}

.BookingSummaryCard-cardWrapper {
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
  }
}

.BookingSummaryCard-fade {
  opacity: 0.6;
}

.BookingSummaryCard-textWithIconText {
  font: var(--font-style-content-2);
}
