@import '~theme/viewport.module.css';

.AccountItem-accountIcon {
  display: none;
  @media (--viewport-lg) {
    display: inline-block;
  }
}

.AccountItem-accountLabel {
  @media (--viewport-lg) {
    display: none;
  }
}
