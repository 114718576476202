@import '~theme/typography.module.css';

.TextWithIcon {
  align-items: center;
  color: inherit; /* TODO: Handle old color prop */
  display: inline-flex;
}

.TextWithIcon--content1 {
  font: var(--font-style-content-1);
  font-weight: inherit;
}

.TextWithIcon--content2 {
  font: var(--font-style-content-2);
  font-weight: inherit;
}

.TextWithIcon--content3 {
  font: var(--font-style-content-3);
  font-weight: inherit;
}

.TextWithIcon--title1 {
  font: var(--font-style-title-1);
  font-weight: inherit;
}

.TextWithIcon--title2 {
  font: var(--font-style-title-2);
  font-weight: inherit;
}

.TextWithIcon--title3 {
  font: var(--font-style-title-3);
  font-weight: inherit;
}

.TextWithIcon--title4 {
  font: var(--font-style-title-4);
  font-weight: inherit;
}

.TextWithIcon--label1 {
  composes: u-typographyLabel1;
}

.TextWithIcon--label2 {
  composes: u-typographyLabel2;
}

.TextWithIcon-icon {
  color: inherit;
  vertical-align: text-top;
}

.TextWithIcon--iconPositionLeft {
  margin-left: 0;
  margin-right: var(--space-base);
}

.TextWithIcon--iconPositionRight {
  margin-left: var(--space-base);
  margin-right: 0;
}

.TextWithIcon--iconSizeSm {
  font-size: var(--font-size-sm);
}

.TextWithIcon--iconSizeMd {
  font-size: var(--font-size-md);
}

.TextWithIcon--iconSizeLg {
  font-size: var(--font-size-lg);
}

.TextWithIcon--iconSizeXl {
  font-size: var(--font-size-xl);
}

.TextWithIcon--marginBottom {
  margin-bottom: calc(var(--space-base) / 2);
}

.TextWithIcon--colorBrandPrimary {
  color: var(--color-brandprimary);
}

.TextWithIcon--colorNeutral900 {
  color: var(--color-neutral-900);
}

.TextWithIcon--iconColor {
  color: var(--TextWithIcon-iconColor);
}
