.ShortlistContent-contentWrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.ShortlistContent-list {
  list-style: none;
  padding: 0;
}

.ShortlistContent-trustpilot {
  margin-left: 25px;
  max-width: 300px;
}
