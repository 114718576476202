@import '~theme/viewport.module.css';

.LogoGrid {
  align-items: center;
  display: grid;
  gap: var(--space-xxl) var(--space-xl);
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  justify-items: center;
  list-style: none;
  margin: 0;
  padding-left: 0;
  text-align: left;
  width: 100%;

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.LogoGrid-logoItem {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  img {
    max-height: 100%;
  }
}
