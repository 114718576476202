.ConfirmModalContent-title {
  margin-bottom: var(--space-md);
}

.ConfirmModalContent-buttonWrapper {
  display: flex;
  justify-content: center;
  width: 100%;

  & button:first-child {
    margin-right: var(--space-sm);
  }
}
