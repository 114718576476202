.HqListingPriceGuaranteeModalContent {
  padding: var(--space-xl);
}

.HqListingPriceGuaranteeModalContent--heading {
  align-items: baseline;
  display: flex;
  margin-bottom: var(--space-md);

  & > h1 {
    margin-left: var(--space-sm);
  }
}
