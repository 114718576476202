@import '~theme/viewport.module.css';

.RegularImageCarouselPosition-backgroundPill {
  background: var(--color-neutral-900);
  border-radius: var(--space-lg);
  padding: var(--space-sm);
}

.RegularImageCarouselPosition-currentPosition {
  color: var(--color-white);
  font-weight: var(--font-weight-medium);
}

.RegularImageCarouselPosition-imageQuantity {
  color: var(--color-neutral-500);
}

.RegularImageCarouselPosition-position {
  bottom: 0;
  font-weight: var(--font-weight-medium);
  padding: 0 calc(var(--space-base) * 12) var(--space-md) var(--space-sm);
  position: absolute;
  right: 0;

  @media (--viewport-md) {
    /* Everything's back in the grid now, so undo our inset */
    margin-left: 0;
    margin-right: 0;
    text-align: right;
  }
}

.RegularImageCarouselPosition-caption {
  color: var(--color-neutral-500);
  margin-left: var(--space-sm);
}

.FullSizeImageCarouselPosition-position {
  position: absolute;
  top: -20px;

  @media (--viewport-md) {
    left: 16.2%; /* Yes, this is oddly specific. But this reliably puts the count at the top left of the image at different viewports */
    top: -28px;
  }
}
