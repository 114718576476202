@import '~theme/viewport.module.css';

.NavigationList {
  display: flex;

  @media (--viewport-lg) {
    align-self: stretch;
    flex: 1;
  }
}

.NavigationList--loading {
  opacity: 0;
}

.NavigationList-list {
  display: none;
  list-style: none;
  margin: 0;
  -webkit-overflow-scrolling: auto;
  overflow-x: auto;
  padding: 0;

  /*
   * At mobile, everything in the NavigationList is shown on a white background,
   * so we want to make sure the links are using neutrals—even in dark mode.
   */
  --navbar-interactive-color: var(--color-neutral-900);
  --navbar-interactive-color-active: var(--color-neutral-500);
  --navbar-interactive-color-hover: var(--color-neutral-500);

  @media (--viewport-lg) {
    align-items: stretch;
    display: flex;
    flex: 1;
    gap: var(--space-md);
    height: initial;
    justify-content: flex-end;
    overflow-x: initial;
    position: initial;
    z-index: 0;

    /*
     * At desktop, NavigationList will use whatever background color the NavBar
     * has—whether that's dark mode or not. So, we should also inherit the link
     * and button colors from NavBar, so that they are visible on whatever the
     * chosen background color is.
     */
    --navbar-interactive-color: inherit;
    --navbar-interactive-color-active: inherit;
    --navbar-interactive-color-hover: inherit;
  }
}

.NavigationList--open {
  & .NavigationList-list {
    background-color: var(--color-white);
    display: block;
    height: calc(100vh - 68px);
    left: 0;
    position: absolute;
    right: 0;
    top: 68px;
    z-index: 1;
  }
}
