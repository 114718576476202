@keyframes load-in {
  0% {
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.ShortlistButton {
  align-items: center;
  color: var(--color-neutral-900);
  cursor: pointer;
  display: flex;
  grid-area: shortlist-button;
  justify-content: center;

  &:hover,
  &:focus {
    color: var(--color-brandprimary);
  }
  &:active {
    color: var(--color-brandprimary-hover);
  }
}

.ShortlistButton--saveButton {
  background-color: var(--color-white);
  border: 1px solid var(--color-neutral-100);
  border-radius: var(--space-xl);
  box-shadow: var(--shadow-resting);
  color: var(--color-neutral-900);
  line-height: 0;
  padding: 0;
  text-align: center;
}

.ShortlistButton-saveButtonLabel {
  color: currentcolor;
  display: inline;
  font: var(--font-style-content-3);
  padding-left: var(--space-xs);
}

.ShortlistButton--saveAndCompareButton {
  align-items: center;
  display: flex;
}

.ShortlistButton-saveAndCompareButtonlabel {
  font: var(--font-style-content-2);
  font-family: var(--font-family);
  margin-left: 4px;
  text-decoration: underline;
}

.ShortlistButton--iconOnly {
  height: var(--space-xxl);
  width: var(--space-xxl);
}

.ShortlistButton--iconLabel {
  font: unset;
  height: var(--space-xxl);
  padding: 0 var(--space-md);
}

.ShortlistButton--iconLabelNav {
  padding: 0;
}

.ShortlistButton-icon {
  vertical-align: middle;
}

.ShortlistButton--loadingButton {
  background-color: var(--color-white);
  height: var(--space-xxl);
  padding: 0;
  width: var(--space-xxl);

  &:hover {
    background-color: var(--color-white);
  }
}

.ShortlistButton-loading {
  animation: load-in 0.75s infinite ease-in-out;
  background-color: var(--color-neutral-300);
  border-radius: var(--space-lg);
  height: var(--space-xxl);
  width: var(--space-xxl);
}

.ShortlistButton-shortlistedIcon {
  color: var(--color-brandprimary);
  &:hover,
  &:focus {
    color: var(--color-brandprimary-hover);
  }

  &:active {
    color: var(--color-brandprimary-active);
  }
}
