/*
 * These values are intended for use in spacing and layout,
 * so typically for things like MARGINS and PADDING.
 *
 * DO NOT use these for font-sizes --> use theme.font instead.
 *
 * Spacing values should always be a multiple of 4.
 * In order to achieve a value that is not explicitly listed,
 * do some math using the available variables.
 * This can help to achieve consistency in spatial relationships.
 *
 * NB. No cheating! "4 x 3.75" doesn't count as a multiple of 4. Use whole numbers.
 *
 *Example 1:
 * margin: var(--space-md) var(--space-lg; // margin: 16px 24px
 *
 * Example 2:
 * margin: calc(var(--space-base) * 12); // margin: 48px
 *
 * Example 3:
 * margin: calc(var(--space-base) * 15) 0 0; // margin: 60px 0 0
 */

:root {
  --space-base: 4px;
  --space-xs: 4px;
  --space-sm: 8px;
  --space-md: 16px;
  --space-lg: 24px;
  --space-xl: 32px;
  --space-xxl: 40px;
  --space-xxxl: 80px;

  /* 44x44 is the recommended minimum iOS touch target size */
  --space-minimumtargetsize: 44px;
}
