@import '~theme/viewport.module.css';

.NavBar {
  align-items: center;
  background-color: white;
  border-bottom: 1px solid var(--color-neutral-100);
  display: flex;

  /* NOTE: this is defined in theme/global.css, as it's used by other components */
  height: var(--NavBar-height);
  justify-content: space-between;
  padding: var(--space-sm) var(--space-md);
  position: relative;
  z-index: var(--z-index-nav);

  /*
   * NAVIGATION LINK AND BUTTON STYLING
   *
   * In our nav, our link coloring needs to vary quite a bit between different
   * states, depending on things like dark mode, screen size, and scroll state.
   *
   * We've created these CSS custom properties, so we can control these colors
   * in each state, and at each level of the nav.
   *
   * If you need to change link or button colors for any reason, you should
   * override these properties, rather than setting the colors directly.
   */
  --NavBar-interactiveColor: var(--color-neutral-900);
  --NavBar--active-interactiveColor: var(--color-neutral-500);
  --NavBar-onHover-interactiveColor: var(--color-neutral-500);

  & a {
    &:link,
    &:visited {
      color: var(--NavBar-interactiveColor);
    }

    &:active {
      color: var(--NavBar--active-interactiveColor);
      text-decoration: none;
    }

    &:focus,
    &:hover {
      color: var(--NavBar-onHover-interactiveColor);
      text-decoration: none;
    }
  }

  & button {
    color: var(--NavBar-interactiveColor);

    &:active {
      color: var(--NavBar--active-interactiveColor);
    }

    &:focus,
    &:hover {
      color: var(--NavBar-onHover-interactiveColor);
    }
  }
}

.NavBar--open {
  border-color: var(--color-white);
  z-index: var(--z-index-drawer);

  @media (--viewport-lg) {
    border-color: var(--color-neutral-100);
  }
}

.NavBar-logo {
  flex: 0 1 0;

  /* The logo should always show in Hubble colors */
  --NavBar-interactiveColor: var(--color-brandprimary);
  --NavBar--active-interactiveColor: var(--color-brandprimary-active);
  --NavBar-onHover-interactiveColor: var(--color-brandprimary-hover);
}

.NavBar-accessory {
  flex: 1 1 auto;

  @media (--viewport-lg) {
    flex: 0 1 auto;
  }
}
