@import '~theme/viewport.module.css';

.UICard {
  background-color: var(--UICard--backgroundColor);
  border: 1px solid var(--color-neutral-200);
  border-radius: var(--space-md);
  box-shadow: var(--UICard--boxShadow);
  color: inherit;
  display: inline-block;
  font-weight: var(--font-weight-light);
  justify-content: space-between;
  line-height: var(--font-lineheight-reset);
  min-width: var(--UICard--minWidth);
  overflow: hidden;
  padding: var(--space-md) var(--space-xl);
  text-decoration: none;

  &:active,
  &:focus,
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.UICard--border {
  border: var(--UICard--border);
}

.UICard--borderRadius {
  border-radius: var(--UICard--borderRadius);
}

.UICard--cursor {
  cursor: pointer;
}

.UICard--fullHeight {
  height: 100%;
}

.UICard--fullWidth {
  width: 100%;
}

.UICard--padding {
  padding: var(--UICard--padding);
}

.UICard--responsivePadding {
  padding: var(--UICard--paddingDefault);
}

.UICard--responsivePaddingSm {
  @media (--viewport-sm) {
    padding: var(--UICard--paddingSm);
  }
}

.UICard--responsivePaddingMd {
  @media (--viewport-md) {
    padding: var(--UICard--paddingMd);
  }
}

.UICard--responsivePaddingLg {
  @media (--viewport-lg) {
    padding: var(--UICard--paddingLg);
  }
}

.UICard--responsivePaddingXl {
  @media (--viewport-xl) {
    padding: var(--UICard--paddingXl);
  }
}

.UICard--responsivePaddingXxl {
  @media (--viewport-xxl) {
    padding: var(--UICard--paddingXxl);
  }
}

.UICard--responsivePaddingXxxl {
  @media (--viewport-xxxl) {
    padding: var(--UICard--paddingXxxl);
  }
}

.UICard--shrinkWrapHeight {
  height: min-content;
}

.UICard--shrinkWrapWidth {
  width: min-content;
}

.UICard--stripEdge {
  border-left: var(--space-sm) solid var(--UICard--stripEdgeColor);
  border-radius: 0 var(--space-md) var(--space-md) 0;
}

/** HOVER TRANSITION STYLES */
.UICard--boxShadowHoverFocus {
  -webkit-box-shadow: var(--UICard--boxShadow);
  box-shadow: var(--UICard--boxShadow);
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;

  &:focus,
  &:hover {
    -webkit-box-shadow: var(--shadow-focus);
    box-shadow: var(--UICard--shadow-focus);
  }
}

.UICard--boxShadowHoverRaised {
  -webkit-box-shadow: var(--UICard--boxShadow);
  box-shadow: var(--UICard--boxShadow);
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;

  &:focus,
  &:hover {
    -webkit-box-shadow: var(--shadow-subtle);
    box-shadow: var(--shadow-subtle);
  }
}

.UICard--boxShadowHoverResting {
  -webkit-box-shadow: var(--UICard--boxShadow);
  box-shadow: var(--UICard--boxShadow);
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;

  &:focus,
  &:hover {
    -webkit-box-shadow: var(--shadow-raised);
    box-shadow: var(--shadow-raised);
  }
}

.UICard--boxShadowHoverSubtle {
  -webkit-box-shadow: var(--UICard--boxShadow);
  box-shadow: var(--UICard--boxShadow);
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;

  &:focus,
  &:hover {
    -webkit-box-shadow: var(--shadow-resting);
    box-shadow: var(--shadow-resting);
  }
}
