/*
 * Wherever possible, we want to use shadows from this file.
 *
 * Using out-of-band drop shadows can create an unusual, visually-confusing
 * experience. Shadows come from light sources and distance; those should not
 * change arbitrarily from component to component.
 */

:root {
  --shadow-focus: 0 0 4px 6px rgb(var(--color-neutral-900-rgb) / 20%);
  --shadow-raised: 0 60px 80px 0 rgb(var(--color-neutral-900-rgb) / 10%);
  --shadow-resting: 0 10px 20px 0 rgb(var(--color-neutral-900-rgb) / 10%);
  --shadow-subtle: 0 2px 4px 0 rgb(var(--color-neutral-900-rgb) / 10%);
}
