@import '~theme/viewport.module.css';

.NavBarMenuItem {
  display: block;

  @media (--viewport-lg) {
    align-items: center;
    display: flex;
  }
}

.NavBarMenuItem--relative {
  /* We use this style when we want the menu to be able to position itself
   * relative to the menu item; for example, with SimpleMenu.
   *
   * With a MegaMenu, we _don't_ want relative positioning, because it will want
   * to fill the full width of the NavBar.
   */
  position: relative;
}

.NavBarMenuItem-button {
  align-items: center;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  font: inherit;
  font: var(--font-style-content-2);
  font-family: var(--font-family);
  font-weight: var(--font-weight-medium);
  justify-content: space-between;
  outline: inherit;
  padding: 0;
  padding: var(--space-md) var(--space-lg);
  text-align: left;
  transition: color 0.3s ease;
  white-space: nowrap;
  width: 100%;

  &:active,
  &:focus,
  &:hover {
    background-color: var(--color-neutral-50);
  }

  @media (--viewport-lg) {
    align-items: center;
    display: flex;
    padding: 0;
    width: auto;

    &:active,
    &:focus,
    &:hover {
      background-color: var(--color-transparent);
    }
  }
}

.NavBarMenuItem-icon {
  display: inline-block;
  transform: rotate(0);
  transition: transform 0.3s;
}

.NavBarMenuItem--open {
  & .NavBarMenuItem-button {
    &,
    &:active,
    &:focus,
    &:hover {
      color: var(--color-brandprimary);
    }
  }

  & .NavBarMenuItem-icon {
    transform: rotate(180deg);
  }
}
