@import '~theme/viewport.module.css';

.FloatingNavigationAnchor {
  --FloatingNavigationAnchor-listingNavigationHeight: 60px;

  position: absolute;
  top: var(--FloatingNavigationAnchor-listingNavigationHeight);

  @media (--viewport-md) {
    --FloatingNavigationAnchor-listingNavigationHeight: 130px;
  }
}
