.PassBadge {
  color: var(--color-brandprimary);
}

.PassBadge-icon {
  color: var(--color-brandprimary);
  font-size: var(--font-size-xl);
  margin-bottom: calc(var(--space-xs) / 2);
  margin-right: var(--space-xs);
  vertical-align: middle;
}
