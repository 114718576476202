@import '~theme/viewport.module.css';

.Actions {
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);

  @media (--viewport-md) {
    flex-direction: row;
    min-width: 355px;
  }
}

.Actions-scheduleAViewing {
  border-color: var(--color-brandprimary);
  color: var(--color-brandprimary);

  &:focus,
  &.sb__focus,
  &:hover,
  &.sb__hover,
  &:active,
  &.sb__active {
    /* Reset default <a> styles */
    background-color: var(--color-brandprimary);
    color: var(--color-white);
  }

  &.is-liked {
    &:disabled {
      background-color: var(--color-brandprimary);
      border-color: var(--color-brandprimary);
      color: var(--color-white);
    }
  }
}

.Actions-drawer {
  padding: var(--space-md);

  @media (--viewport-md) {
    width: 490px;
  }
}
