.ViewingBasket {
  display: flex;
  flex-direction: column;
  height: calc(
    100vh - (var(--space-md) * 2)
  ); /* Take into account top and bottom padding of the drawer */
}

.ViewingBasket-heading {
  display: flex;
  justify-content: space-between;
}

.ViewingBasket-items {
  overflow-y: auto;
}

.ViewingBasket-item {
  border-bottom: 1px solid var(--color-neutral-100);
  padding: var(--space-md) 0;

  &:last-child {
    border-bottom: none;
  }
}

.ViewingBasket-actions {
  border-bottom: 1px solid var(--color-neutral-100);
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
  padding: var(--space-md) 0;
}

.ViewingBasket-advisor {
  align-items: center;
  display: flex;
  gap: var(--space-sm);
  padding: var(--space-md) 0;

  &.is-genericAdvisor {
    flex-direction: column;
  }
}

.ViewingBasket-contact {
  text-align: center;
}

.ViewingBasket-contactDetails {
  display: flex;
  gap: var(--space-md);
  justify-content: center;
}
