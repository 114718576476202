@import '~theme/viewport.module.css';

.AdvisorPromotionCard {
  display: flex;
}
.AdvisorPromotionCard-telWrapper {
  font-weight: var(--font-weight-medium);
  margin-top: var(--space-sm);
}

.AdvisorPromotionCard-advisor {
  align-items: center;
  display: flex;
  gap: var(--space-md);
}

.AdvisorPromotionCard-advisorContent {
  width: 100%;
}

.AdvisorPromotionCard-advisorContact {
  display: flex;
  gap: var(--space-xs);
}

.AdvisorPromotionCard-whatsapp {
  display: flex;
  gap: var(--space-xs);
}
