@import '~theme/typography.module.css';

.CustomCheckbox {
  cursor: pointer;
  display: inline-block;
  height: 40px;
  position: relative;
  width: 40px;
}

.CustomCheckbox input {
  opacity: 0;
  position: absolute;
}

.CustomCheckbox--checkmark {
  background-color: var(--color-white);
  border: 1px solid var(--color-neutral-200);
  border-radius: var(--space-xs);
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 40px;
}

.CustomCheckbox input:checked ~ .CustomCheckbox--checkmark {
  background-color: var(--color-brandsecondary);
  border: 1px solid var(--color-brandsecondary);
}

.CustomCheckbox input:disabled ~ .CustomCheckbox--checkmark {
  background-color: var(--color-neutral-100);
  border: 1px solid var(--color-neutral-200);
}

.CustomCheckbox--content {
  color: var(--color-neutral-900);
  composes: u-typographyLabel1;
  left: 10px;
  position: absolute;
  top: 10px;
}

.CustomCheckbox input:disabled ~ .CustomCheckbox--content {
  color: var(--color-neutral-400);
}
