.HqListingUspCard {
  height: 100%;
}

.HqListingUspCard-modalTrigger {
  color: var(--color-neutral-900);
  cursor: pointer;
  display: flex;
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
}

.HqListingUspCard-title {
  /* Establish a min-height, so that text content below each title aligns properly */
  min-height: var(--space-xl);
}
