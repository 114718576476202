.u-preventScroll {
  overflow: hidden;
}

.u-visuallyHidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px !important;
  overflow: hidden;
  position: absolute !important;
  width: 1px !important;
}

.u-displayAboveMd {
  display: none;

  @media (--viewport-md) {
    display: block;
  }
}

.u-displayBelowMd {
  display: block;

  @media (--viewport-md) {
    display: none;
  }
}

.u-displayBelowLg {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.u-displayAboveLg {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}

.u-displayNone {
  display: none;
}

.u-displayFlex {
  display: flex;
}

.u-displayMdFlex {
  @media (--viewport-md) {
    display: flex;
  }
}

.u-displayMdNone {
  @media (--viewport-md) {
    display: none;
  }
}

.u-displayLgNone {
  @media (--viewport-lg) {
    display: none;
  }
}
