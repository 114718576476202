@import '~theme/viewport.module.css';

.Section {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: var(--Section--verticalPadding);
  padding-left: var(--Section--horizontalPadding);
  padding-right: var(--Section--horizontalPadding);
  padding-top: var(--Section--verticalPadding);

  &.Section--maxWidth {
    max-width: 768px;
  }
}

.Section--smBreakpoint {
  @media (--viewport-sm) {
    &.Section--leftAligned {
      margin-left: 0;
    }

    &.Section--maxWidth {
      @supports (display: grid) {
        max-width: var(--grid-maxWidth);
      }
    }
  }
}

.Section--mdBreakpoint {
  @media (--viewport-md) {
    &.Section--leftAligned {
      margin-left: 0;
    }

    &.Section--maxWidth {
      @supports (display: grid) {
        max-width: var(--grid-maxWidth);
      }
    }
  }
}

.Section--lgBreakpoint {
  @media (--viewport-lg) {
    &.Section--leftAligned {
      margin-left: 0;
    }

    &.Section--maxWidth {
      @supports (display: grid) {
        max-width: var(--grid-maxWidth);
      }
    }
  }
}

.Section--xlBreakpoint {
  @media (--viewport-xl) {
    &.Section--leftAligned {
      margin-left: 0;
    }

    &.Section--maxWidth {
      @supports (display: grid) {
        max-width: var(--grid-maxWidth);
      }
    }
  }
}

.Section--xxlBreakpoint {
  @media (--viewport-xxl) {
    &.Section--leftAligned {
      margin-left: 0;
    }

    &.Section--maxWidth {
      @supports (display: grid) {
        max-width: var(--grid-maxWidth);
      }
    }
  }
}

.Section--xxxlBreakpoint {
  @media (--viewport-xxxl) {
    &.Section--leftAligned {
      margin-left: 0;
    }

    &.Section--maxWidth {
      @supports (display: grid) {
        max-width: var(--grid-maxWidth);
      }
    }
  }
}
