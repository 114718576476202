@import '~theme/viewport.module.css';

.CardGrid {
  --CardGrid-minCardWidth: 288px;

  display: grid;
  grid-gap: var(--space-xxl) var(--space-lg);
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--CardGrid-minCardWidth), 1fr)
  );
}

.CardGrid-item {
  align-items: stretch;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;

  &:last-child {
    margin-bottom: 0;
  }

  @supports (display: grid) {
    margin-bottom: 0;
  }

  /*
   * Handle CardGrids of 3 Items in medium-sized viewports.
   *
   * Style an Item that is both:
   * 1. the 3rd Item &
   * 2. the last Item
   */
  &:nth-child(3):last-child {
    /* Viewport is wide enough for 2 Items, center the 3rd Item (in 2nd row) */

    @media (width >= calc(var(--CardGrid-minCardWidth) * 2 + var(--space-lg) * 2}) ) {
      grid-column: 1 / 3;
    }

    /* Viewport is wide enough for 3+ Items, restore auto column behaviour */

    @media (--viewport-lg) {
      grid-column: auto;
    }
  }
}
