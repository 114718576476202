.HowDoViewingsWork-advisor {
  align-items: center;
  display: flex;
  gap: var(--space-md);
}

.HowDoViewingsWork-advisorContent {
  width: 100%;
}

.HowDoViewingsWork-advisorContact {
  display: flex;
  gap: var(--space-xs);
}

.HowDoViewingsWork-whatsapp {
  display: flex;
  gap: var(--space-xs);
}

.HowDoViewingsWork-quote {
  color: var(--color-neutral-600);
  font: var(--font-style-content-3);
}

.HowDoViewingsWork-author {
  color: var(--color-neutral-600);
  font: var(--font-style-content-3);
  font-style: normal;
  font-weight: var(--font-weight-medium);
}
