.SuccessModalContactDetails {
  text-align: center;
}

.SuccessModalContactDetails-link {
  color: var(--color-brandprimary);
  font-weight: var(--font-weight-medium);

  &:hover,
  &:focus {
    color: var(--color-brandprimary-hover);
  }

  &:active {
    color: var(--color-brandprimary-active);
  }
}
