@import '~theme/viewport.module.css';

.NavBarButtonItem {
  display: block;
  white-space: nowrap;

  @media (--viewport-lg) {
    align-items: center;
    display: flex;
  }
}

.NavBarButtonItem-button {
  cursor: pointer;
  display: block;
  font: var(--font-style-content-2);
  font-family: var(--font-family);
  font-weight: var(--font-weight-medium);
  padding: var(--space-md) var(--space-lg);
  transition: color 0.3s ease;

  &:active,
  &:focus,
  &:hover {
    background-color: var(--color-neutral-50);
  }

  @media (--viewport-lg) {
    padding: 0;

    &:active,
    &:focus,
    &:hover {
      background-color: var(--color-transparent);
    }
  }
}
