@import '~theme/viewport.module.css';

/*
 * Reset the bottom margin. If a heading needs space below it, use
 * VerticalSpacing.
 */
.Heading {
  color: inherit;
  display: block;
  font: var(--Heading-defaultType);
  margin: 0;
}

.Heading--centered {
  text-align: center;
}

.Heading--underlined {
  text-decoration: underline;
}

.Heading--color {
  color: var(--Heading-color);
}

.Heading--smType {
  @media (--viewport-sm) {
    font: var(--Heading-smType);
  }
}

.Heading--mdType {
  @media (--viewport-md) {
    font: var(--Heading-mdType);
  }
}

.Heading--lgType {
  @media (--viewport-lg) {
    font: var(--Heading-lgType);
  }
}

.Heading--xlType {
  @media (--viewport-xl) {
    font: var(--Heading-xlType);
  }
}

.Heading--xxlType {
  @media (--viewport-xxl) {
    font: var(--Heading-xxlType);
  }
}

.Heading--xxxlType {
  @media (--viewport-xxxl) {
    font: var(--Heading-xxxlType);
  }
}
