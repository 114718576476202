.Paragraph {
  color: inherit;
  margin: 0 0 var(--space-lg);

  &:last-child {
    margin-bottom: 0;
  }
}

.Paragraph--content1 {
  font: var(--font-style-content-1);
}

.Paragraph--content2 {
  font: var(--font-style-content-2);
}

.Paragraph--content3 {
  font: var(--font-style-content-3);
}

.Paragraph--deemphasized {
  color: var(--color-neutral-500);
}

.Paragraph--centered {
  text-align: center;
}

.Paragraph--marginless {
  margin: 0;
}

.Paragraph--measured {
  max-width: 33em;
}
