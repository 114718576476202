.ProductOverviewWrapper-content {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin-right: var(--space-md);
}

.ProductOverviewWrapper-image {
  margin-right: var(--space-md);
  max-width: 100px;
}
