@import '~theme/viewport.module.css';

.LocationItem-link {
  font-size: var(--font-size-xs);
}

.LocationItem-active {
  color: var(--color-brandprimary);
}

.LocationItem-item {
  @media (--viewport-lg) {
    width: 100%;
  }
}
