.RecommendationMap {
  height: 100%;
  min-height: 100%;
  position: relative;
  width: 100%;
}

.RecommendationMap-marker {
  background-color: var(--color-neutral-900);
  border-radius: 8px;
  color: #fff;
  font-size: var(--font-size-sm);
  padding: var(--space-sm) var(--space-md);
  position: relative;

  &::after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid var(--color-neutral-900);
    content: '';
    height: 0;
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translate(-50%, 0);
    width: 0;
  }
}

.RecommendationMap-marker--selected {
  background-color: var(--color-brandprimary);
  &::after {
    border-top: 8px solid var(--color-brandprimary);
  }
}

.RecommendationMap-nanoCardContent {
  padding-top: var(--space-xs);
}
