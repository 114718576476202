.Toggle-input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.Toggle-button {
  border-radius: 16px;
  box-sizing: initial;
  cursor: pointer;
  display: inline-block;
  height: 22px;
  outline: 0;
  padding: 5px;
  position: relative;
  transition: all 0.4s ease;
  user-select: none;
  width: 50px;

  &::after {
    background: var(--color-white);
    border-radius: 16px;
    box-shadow:
      0 0 0 2px rgb(0 0 0 / 10%),
      0 4px 0 rgb(0 0 0 / 8%);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: relative;
    transition:
      all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
      padding 0.3s ease,
      margin 0.3s ease;
    width: 22px;
  }

  &:focus::after,
  &:active::after {
    box-shadow:
      0 0 0 2px rgb(0 0 0 / 10%),
      0 4px 0 rgb(0 0 0 / 8%),
      inset 0px 0px 0px 3px #9c9c9c;
    box-sizing: initial;
  }
}

.Toggle-button[disabled] {
  background: var(--color-neutral-300);
  cursor: default;

  &:focus,
  &::after,
  &:active::after {
    background: var(--color-neutral-100);
    box-shadow: none;
  }
}

.Toggle-button--on {
  &:active {
    box-shadow: none;
  }

  &::after {
    left: 56%;
  }
}
