.AlertContext {
  left: 0;
  max-height: 100vh;
  overflow-y: auto;
  padding: var(--space-md) var(--space-md) 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--z-index-toast);
}
