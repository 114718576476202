@import '~theme/viewport.module.css';

.ResultsWrapper-dropdown {
  background-color: var(--color-white);
  height: 100%;
  overflow: visible;
  position: absolute;
  top: 40px;
  width: 100%;
  z-index: var(--z-index-drawer);

  @media (--viewport-lg) {
    top: 48px;
  }
}
