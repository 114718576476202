@import '~theme/viewport.module.css';

.SimpleMenuList {
  font: var(--font-style-content-3);

  & > * {
    margin-bottom: var(--space-md);

    /* Create an indent at smaller breakpoints. Helps maintain a visual hierarchy. */
    padding-left: var(--space-sm);
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  @media (--viewport-lg) {
    & > * {
      /* At larger screen sizes, the menu is a popover, so remove the indent. */
      padding-left: 0;
    }
  }
}
