.ToggleButton {
  align-items: center;
  color: var(--color-neutral-300);
  cursor: pointer;
  display: flex;
  font: var(--font-style-title-3);
  font-size: var(--font-size-sm);
  gap: var(--space-sm);
}

.ToggleButton-label--active {
  color: var(--color-neutral-800);
}

.ToggleButton-label--disabled {
  cursor: default;
}

.ToggleButton-button {
  background: var(--color-brandsecondary);
}
