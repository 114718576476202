@import '~theme/viewport.module.css';

.PassBookingContent-rowDetailsWrapper {
  align-items: center;
  border-top: 1px solid var(--color-neutral-100);
  display: grid;
  grid-gap: var(--space-sm);
  grid-template-areas: 'copy button';
  justify-content: space-between;
  padding: var(--space-md);
}

.PassBookingContent-requestToBookTextWrapper {
  align-items: baseline;
  display: flex;
  flex-direction: column;
  grid-area: copy;

  @media (--viewport-sm) {
    flex-direction: row;
    gap: var(--space-xl);
  }
}
