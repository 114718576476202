.AdvisorPhotos--medium {
  display: flex;
  justify-content: center;
  margin: 0 var(--space-sm) 0 var(--space-sm);
  width: 100%;
}

.AdvisorPhotos--large {
  display: flex;
  justify-content: center;
  margin: 0 0 var(--space-lg);
}
