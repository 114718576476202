/* stylelint-disable order/properties-alphabetical-order */
.Select-display {
  font: var(--font-style-content-2);

  /* Need to reset from label text styles */
  letter-spacing: 0;
  font-family: var(--font-family);
  text-transform: none;

  /* Actual styles */
  border: 1px solid var(--color-neutral-500);
  border-radius: var(--space-xs);
  background-color: var(--color-white);
  color: var(--color-neutral-900);
  display: flex;
  height: calc(var(--space-base) * 12); /* 48px; */
  justify-content: space-between;
  padding: calc(var(--space-base) * 3);
  width: 100%;

  &:active,
  &:focus {
    border: 1px solid var(--color-neutral-900);
  }

  select:disabled + & {
    background: var(--color-neutral-200);
    color: var(--color-neutral-500);
  }
}

.Select--invalid {
  border: 1px solid var(--color-error);
}

.Select-text {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Select--icon {
  padding-left: 30px;
}

.Select--iconWithLabel {
  margin: 30px 0 0 var(--space-xs);
  position: absolute;
}

.Select--iconWithoutLabel {
  margin: 10px 0 0 var(--space-xs);
  position: absolute;
}

/**
   * Style the display component, when it is:
   * 1. Not adjacent to a focused or active <select>, and
   * 2. not within the first child of an .hbl-InputGroup
   */

:global(.hbl-InputGroup) > :first-child .Select-display {
  border-bottom-left-radius: var(--space-xs); /* See 1. */
  border-top-left-radius: var(--space-xs); /* See 1. */
}

:global(.hbl-InputGroup) > :last-child .Select-display {
  border-bottom-right-radius: var(--space-xs); /* See 1. */
  border-top-right-radius: var(--space-xs); /* See 1. */
}

/**
   * A bit of complex CSS here. InputGroups are basically attached inputs.
   * Here's the gist if how we accomplish this:
   * 1. Inside an input group, we only want to round the left side of the first
   *    element, and the right side of the last element.
   * 2. Inside an input group, we only want 1px borders between elements. One
   *    border - in this case, the left one - should be transparent on all but
   *    the first element.
   * 3. When an element is active/focused, we want all four borders to show.
   *    This avoids a circumstance where an element appears "open" at one side
   *    because of its 3-sided focus border color.
   *
   * Selectors like this - particularly, a component changing its style because
   * it's contained by another component - is generally discouraged. However,
   * we made an exception here, because there is simply no other good way to
   * accomplish this type of style.
   */

/* Disabling stylelint for the following line because the specificity rule here
 * makes the file harder to read. */

/* stylelint-disable-next-line no-descending-specificity */
:global(.hbl-InputGroup) .Select-display {
  border-radius: 0; /* See 1 */
  flex: 1;
}

:global(.hbl-InputGroup)
  > :not(:first-child)
  select:not(:active, :focus)
  + .Select-display {
  border-left-color: transparent; /* See 2, 3. */
}
