@import '~theme/viewport.module.css';

.ImageCarouselMoveButton-baseButton {
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: calc(var(--space-base) * 9);
  justify-content: center;
  overflow: hidden;
  transition-duration: var(--animation-short);
  transition-timing-function: linear;
  width: calc(var(--space-base) * 9);

  &:disabled {
    opacity: 0.3;
  }

  @media (--viewport-md) {
    height: calc(var(--space-base) * 11);
    width: calc(var(--space-base) * 11);
  }
}

.ImageCarouselMoveButton-button {
  background-color: var(--color-white);
  color: var(--color-neutral-900);
  transition-property: color;

  &:hover:not(:disabled),
  :focus:not(:disabled) {
    color: var(--color-neutral-800);
  }

  &:active:not(:disabled) {
    color: var(--color-neutral-700);
  }
}

.ImageCarouselMoveButton-invertedButton {
  background-color: var(--color-neutral-900);
  color: var(--color-white);
  transition-property: background-color;

  &:hover:not(:disabled),
  :focus:not(:disabled) {
    background-color: var(--color-neutral-800);
  }

  &:active:not(:disabled) {
    background-color: var(--color-neutral-700);
  }
}

.ImageCarouselMoveButton-button--small {
  @media (--viewport-md) {
    height: calc(var(--space-base) * 9);
    width: calc(var(--space-base) * 9);
  }
}

.ImageCarouselMoveButton-icon {
  font-size: var(--font-size-xl);
  height: var(--space-md);
  line-height: var(--space-md);
  margin: 0;
  padding: 0;
  position: relative;
  right: var(--ImageCarouselMoveButton-iconPositionAddOn);
  width: var(--space-md);
}

.ImageCarouselMoveButton-buttonWrapper {
  margin-bottom: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.ImageCarouselMoveButton-nextButtonWrapper {
  right: var(--space-sm);

  @media (--viewport-md) {
    right: var(--space-xl);
  }
}

.ImageCarouselMoveButton-nextButtonWrapper--small {
  @media (--viewport-md) {
    right: var(--space-sm);
  }
}

.ImageCarouselMoveButton-previousButtonWrapper {
  left: var(--space-sm);

  @media (--viewport-md) {
    left: var(--space-xl);
  }
}

.ImageCarouselMoveButton-previousButtonWrapper--small {
  @media (--viewport-md) {
    left: var(--space-sm);
  }
}
