.office-stats {
  display: flex;
  flex-wrap: wrap;
  grid-area: detail;
  list-style: none;
  margin: 0;
  padding: 0;
}

.office-stats > li {
  margin-right: var(--space-md);
}
