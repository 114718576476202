@import '~theme/viewport.module.css';

.SolutionsMenuContent-featuredContentBackground {
  position: relative;

  &::before {
    background: var(--color-brandsecondary-background);
    bottom: 0;
    content: '';
    display: block;
    left: -16px;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: var(--z-index-nav) + 1;

    /**
     * It's really hard to make this background color snap to the edges once the
     * Section component starts introducing dynamically sized gutters so this is a
     * workaround where we say "make the background wide enough that it fits from left to
     * right between this breakpoint and the next, but just hide how wide it is".
     */
    @media (--viewport-md) {
      left: -128px;
      overflow: hidden;
      width: calc(100vw + 128px);
    }

    /**
     * At this point the nav is in the header and the Mega Menu if the full width
     * of the viewport. We need to make sure the background color is wide enough
     * to cover just the section of the Mega Menu that contains the "Featured" content.
     */
    @media (--viewport-lg) {
      bottom: -24px;
      left: -24px;
      top: -24px;
    }
  }
}

.SolutionsMenuContent-featuredContent {
  padding: var(--space-md) 0;
  position: inherit;
  z-index: var(--z-index-nav) + 2;

  @media (--viewport-lg) {
    padding: 0;
  }
}
