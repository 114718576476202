@import '~theme/typography.module.css';

.BackButton {
  background: none;
  border: none;
  color: var(--color-neutral-900);
  cursor: pointer;
  padding: var(--space-sm) 0;
}

.BackButton--primary {
  color: var(--color-brandprimary);

  &:hover,
  &.sb__hover,
  &:focus,
  &.sb__focus {
    color: var(--color-brandprimary-hover);
  }

  &:active,
  &.sb__active {
    color: var(--color-brandprimary-active);
  }
}

.BackButton--secondary {
  color: var(--color-neutral-900);

  &:hover,
  &.sb__hover,
  &:focus,
  &.sb__focus {
    color: var(--color-neutral-700);
  }

  &:active,
  &.sb__active {
    color: var(--color-neutral-500);
  }
}

.BackButton--content1 {
  composes: u-typographyContent1;
}

.BackButton--content2 {
  composes: u-typographyContent2;
}

.BackButton--content3 {
  composes: u-typographyContent3;
}

.BackButton--label1 {
  composes: u-typographyLabel1;
}

.BackButton--title1 {
  composes: u-typographyTitle1;
}

.BackButton--title2 {
  composes: u-typographyTitle2;
}

.BackButton--title3 {
  composes: u-typographyTitle3;
}

.BackButton--title4 {
  composes: u-typographyTitle4;
}

.BackButton-icon {
  font-size: var(--font-size-lg);
  margin-right: var(--space-sm);
  margin-top: -2px;
  vertical-align: middle;
}

.BackButton-link {
  &:hover,
  &:active,
  &:focus {
    color: var(--color-neutral-900);
    text-decoration: inherit;
  }
}
