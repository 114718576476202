@import '~theme/viewport.module.css';

.ModalNewTemplateWithSidebar {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 850px;
  position: relative;

  @media (--viewport-lg) {
    grid-template-columns: 1fr 1fr;
  }
}

.ModalNewTemplateWithSidebar-gridItem {
  margin-bottom: var(--space-lg);
  min-width: 0;

  &:last-child {
    margin-bottom: 0;
  }

  @supports (display: grid) {
    margin-bottom: 0;
  }

  @media (--viewport-lg) {
    max-height: 85vh;
    overflow: auto;
  }
}

.ModalNewTemplateWithSidebar-sidebar {
  /* 
  These are the current default values following our designs, 
  if you get a new design that would change these ask if they
  should be updated to everywhere or just the new component.
  */
  --Modal-sidebar--bgColor: var(--color-brandsecondary-background);
  --Modal-sidebar-bgGradient: var(--color-gradient-pinkblue-diagonal);

  background-color: var(--Modal-sidebar--bgColor);
  background-image: var(--Modal-sidebar-bgGradient);
}

.ModalNewTemplateWithSidebar-contentContainer {
  padding: var(--space-md) var(--space-lg) var(--space-lg) var(--space-lg);

  @media (--viewport-lg) {
    height: fit-content;
    min-height: 100%;
    padding: var(--space-xl);
  }
}
