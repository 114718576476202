@import '~theme/typography.module.css';
@import '~theme/viewport.module.css';

.MeetingRoomProducts-inner {
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;

  @media (--viewport-md) {
    align-items: center;
    flex-wrap: unset;
  }
}

.MeetingRoomProducts-facilities {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.MeetingRoomProducts-truncatedText {
  width: 200px;

  p {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  @media (--viewport-md) {
    width: 300px;
  }
}

.MeetingRoomProducts-facilitiesContent {
  padding: var(--space-xxl) var(--space-lg);

  @media (--viewport-lg) {
    text-align: center;
  }
}

.MeetingRoomProducts-pricingQuestion {
  cursor: pointer;
}

.MeetingRoomProducts-pricingQuestionContent {
  list-style: none;

  &::-webkit-details-marker {
    /* Disable default <summary> arrow styling */
    display: none;
  }
}

.MeetingRoomProducts-expandIcon {
  font: var(--font-style-content-1);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  line-height: var(--space-lg);

  details[open] &::after {
    content: 'expand_less';
    font-family: var(--font-family-icons);
    vertical-align: bottom;
  }

  details:not([open]) &::after {
    content: 'expand_more';
    font-family: var(--font-family-icons);
    vertical-align: bottom;
  }
}

.MeetingRoomProducts-pricingQuestionHeading {
  composes: material-icon-pseudoelement--before;
  display: block;
  font-weight: var(--font-weight-medium);
  margin: 0 0 var(--space-xs) 0;
  padding-left: var(--space-lg);
  position: relative;

  &::before {
    color: var(--color-brandsecondary);
    content: 'help';
    float: left;
    font-size: var(--font-size-md);
    left: 0;
    position: absolute;
    top: 2px;
  }
}

.MeetingRoomProducts-pricingQuestionDetails {
  font: var(--font-style-content-3);
  padding-left: var(--space-lg);
}

.MeetingRoomProducts-pricingAnswer {
  padding-left: var(--space-lg);
}
