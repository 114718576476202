@import '~theme/viewport.module.css';

.Curve {
  --Curve-actual-height: var(--Curve-height);

  background-color: var(--Curve-backgroundColor);
  height: var(--Curve-actual-height);
  position: relative;
}

.Curve--xs-height {
  height: var(--Curve-xs-height);
}

@media (--viewport-sm) {
  .Curve--sm-height {
    height: var(--Curve-sm-height);
  }
}

@media (--viewport-md) {
  .Curve--md-height {
    height: var(--Curve-md-height);
  }
}

@media (--viewport-lg) {
  .Curve--lg-height {
    height: var(--Curve-lg-height);
  }
}

@media (--viewport-xl) {
  .Curve--xl-height {
    height: var(--Curve-xl-height);
  }
}

@media (--viewport-xxl) {
  .Curve--xxl-height {
    height: var(--Curve-xxl-height);
  }
}

@media (--viewport-xxxl) {
  .Curve--xxxl-height {
    height: var(--Curve-xxxl-height);
  }
}

.Curve-svg {
  fill: var(--Curve-svg--type-fill);
  position: absolute;
}

.Curve-ellipse {
  background-color: var(--Curve-ellipse-backgroundColor);
  clip-path: var(--Curve-ellipse-clipPath);
  content: '';
  inset: 0;
  position: absolute;
}

.Curve--transform {
  transform: rotate(-180deg);
}

.Curve--svgTransform {
  transform: scaleX(-1);
}
