@import './animation.css';
@import './colors.css';
@import './font.css';
@import './grid.css';
@import './shadow.css';
@import './spacing.css';
@import './zIndex.css';
@import './typography.module.css';
@import './utilities.css';
@import './viewport.module.css';

/*
 * This is a global custom property that can be used by components that need to
 * know the size of the nav bar.
 */

:root {
  --NavBar-height: 68px;

  /* Frustratingly necessary to include a unit even though the value is 0 because
  of the way calc works */

  /* stylelint-disable-next-line length-zero-no-unit */
  --PageBanner-stickyHeight: 0px;

  @media (--viewport-lg) {
    --NavBar-height: 84px;
  }
}

@font-face {
  font-display: swap;
  font-family: 'Simplon Norm';
  font-style: italic;
  font-weight: 300;
  src:
    url('../../fonts/SimplonNorm-LightItalic-WebS.woff2') format('woff2'),
    url('../../fonts/SimplonNorm-LightItalic-WebS.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Simplon Norm';
  font-style: normal;
  font-weight: 300;
  src:
    url('../../fonts/SimplonNorm-Light-WebS.woff2') format('woff2'),
    url('../../fonts/SimplonNorm-Light-WebS.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Simplon Norm';
  font-style: normal;
  font-weight: 500;
  src:
    url('../../fonts/SimplonNorm-Medium-WebS.woff2') format('woff2'),
    url('../../fonts/SimplonNorm-Medium-WebS.woff') format('woff');
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;

  /*
   * Height of the nav bar. Prevents the fixed-position nav from overlapping
   * content when using anchor links.
  */
  scroll-padding-top: var(--PageBanner-stickyHeight);
}

.html--autoScroll {
  scroll-behavior: auto;
}

body {
  color: var(--color-neutral-900);
  font-family: var(--font-family);
  font-weight: var(--font-weight-light);
  line-height: var(--font-lineheight-lg);
  margin: 0;
  text-rendering: optimizelegibility;
}

.body--overlayed {
  overflow: hidden;
}

.body--overlayed-narrow-only {
  overflow: hidden;
  @media (--viewport-lg) {
    overflow: auto;
  }
}

strong,
b {
  font-weight: var(--font-weight-medium);
}

a {
  color: var(--color-brandprimary);
  text-decoration: none;
  transition: color var(--animation-short) ease;

  &:hover,
  &:focus {
    color: var(--color-brandprimary-hover);
    text-decoration: underline;
  }

  &:active {
    color: var(--color-brandprimary-active);
    text-decoration: underline;
  }
}

h1:not([class]) {
  font: var(--font-style-hero-2);
}

h2:not([class]) {
  font: var(--font-style-title-1);
}

h3:not([class]) {
  font: var(--font-style-title-3);
}

h4:not([class]) {
  font: var(--font-style-title-4);
}

button {
  background: none;
  border: 0;
}

table:not([class]) {
  border: 1px solid var(--color-neutral-100);
  & tbody > tr:nth-child(odd) {
    background-color: var(--color-neutral-50);
  }
}

/* Browser stylesheet sets its own color for <dialog>, but we always want neutral900 */
dialog {
  color: var(--color-neutral-900);
}
