.TransitStop-listItem {
  display: inline;
  line-height: 0;
  margin: 0 var(--space-xs) 0 0;
  padding-bottom: 0;
  padding-left: 0;

  &:last-of-type {
    margin-right: 0;
  }
}

.TransitStop-title {
  font: var(--font-style-title-3);
}

.TransitStop-lineImage {
  display: inline-block;
  max-width: var(--space-md);
}

.TransitStop-listWrapper {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.TransitStop-walkingTime {
  color: var(--color-neutral-500);
  font: var(--font-style-content-2);
  margin-bottom: var(--space-base);
}

.TransitStop-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--space-md);
  margin-right: var(--space-xxxl);
  padding-bottom: 0;
}
