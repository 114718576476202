.NanoCard-header {
  grid-area: header;
}

.NanoCard {
  --nano-card-arrow-size: 10px;
  --nano-card-border-radius: 6px;
  --nano-card-space: var(--space-sm);

  background: var(--color-white);
  border-radius: var(--nano-card-border-radius);
  box-shadow: var(--shadow-resting);
  color: var(--color-neutral-900);
  display: grid;
  grid-template:
    'image header' auto
    'image content' 1fr
    'image footer' auto
    / auto minmax(0, 1fr);
  left: 50%;
  max-width: 90%;
  padding: var(--nano-card-space);
  position: absolute;
  top: 50%;
  transform: translate(-50%, calc(-100% + var(--nano-card-marker-offset, 0px)));
  transition: box-shadow var(--animation-short);
  width: max-content;

  &:hover {
    box-shadow: var(--shadow-raised);
    color: var(--color-neutral-900);
    text-decoration: none;
  }

  &:hover .NanoCard-header,
  &:focus .NanoCard-header {
    color: var(--color-brandprimary-hover);
  }

  &::after {
    border-color: var(--color-white) transparent transparent transparent;
    border-style: solid;
    border-width: var(--nano-card-arrow-size) var(--nano-card-arrow-size) 0
      var(--nano-card-arrow-size);
    bottom: calc(var(--nano-card-arrow-size) * -1);
    content: '';
    height: 0;
    left: 50%;
    margin-left: calc(var(--nano-card-arrow-size) * -1);
    position: absolute;
    width: 0;
  }
}

.NanoCard-imgWrapper {
  background-color: var(--color-neutral-50);
  border-radius: var(--nano-card-border-radius);
  flex-shrink: 0;
  grid-area: image;
  margin-right: var(--nano-card-space);
  min-height: 96px;
  overflow: hidden;
  width: 96px;
}

.NanoCard-img {
  color: var(--color-transparent);
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.NanoCard-headerText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.NanoCard-content {
  grid-area: content;
}

.NanoCard-footer {
  grid-area: footer;
}
