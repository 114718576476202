@import '~theme/typography.module.css';

.Breadcrumbs-list {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--font-size-xs);
  font-weight: var(--font-size-md);
  line-height: normal;
  list-style: none;
  row-gap: var(--space-xs);
}

.Breadcrumbs-link {
  color: inherit;
  font: var(--font-style-content-3);
  line-height: var(--font-lineheight-sm);
  margin: 0;
}

.Breadcrumbs-breadcrumb {
  composes: material-icon-pseudoelement--after;
  display: inline;
  margin-bottom: 0;
  margin-right: var(--space-xl);
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  &::after {
    color: var(--color-neutral-300);
    content: 'arrow_forward_ios';
    font-size: var(--font-size-sm);
    position: absolute;
    right: calc(var(--space-lg) * -1);
  }

  &:last-child::after {
    content: none;
  }
}
