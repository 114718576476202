@keyframes fade-in {
  0% {
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.BottomAccessoryButton-action {
  align-items: center;
  border-right: 2px solid var(--color-neutral-100);
  color: var(--color-neutral-900);
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: var(--space-sm) var(--space-md);
  text-align: center;

  &:disabled {
    color: var(--color-neutral-500);
    cursor: default;
  }

  &:last-child {
    border-right: 0;
  }
}

.BottomAccessoryButton-text {
  font: var(--font-style-content-3);
  white-space: nowrap; /* Don't allow text to wrap onto multiple lines. */

  button:hover &,
  button:focus & {
    text-decoration: underline;
  }

  button:disabled:hover &,
  button:disabled:focus & {
    text-decoration: none;
  }
}

.BottomAccessoryButton-loading {
  animation: fade-in 0.75s infinite ease-in-out;
  background-color: var(--color-neutral-300);
  border-radius: var(--space-md);
  height: var(--space-xl);
  width: var(--space-xl);
}

.BottomAccessoryButton-icon {
  color: var(--BottomAccessoryButton-iconColor);
}
