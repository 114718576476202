.MultipleSelect-display {
  /* Actual styles */
  border: 1px solid var(--color-neutral-500);
  border-radius: 4px;
  color: var(--color-neutral-900);
  font: var(--font-style-content-2);
  font-family: var(--font-family);

  option {
    font-weight: var(--font-weight-light);
  }

  &:disabled,
  > optgroup:disabled {
    background: var(--color-neutral-200);
    color: var(--color-neutral-500);
  }
}
