@import '~theme/viewport.module.css';

.button-group {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.button-wrapper {
  text-align: right;
  width: 100%;
}

.link-wrapper {
  padding-top: var(--space-sm);
  text-align: right;
  width: 100%;
}

.usp-wrapper {
  padding-top: var(--space-sm);
  text-align: left;
  width: 100%;
}
