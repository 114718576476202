@import '~theme/typography.module.css';

.Link {
  &:link,
  &:visited {
    color: var(--color-brandprimary);
    text-decoration: none;
    transition: color 0.2s ease;
  }

  &:hover,
  &:focus {
    color: var(--color-brandprimary-hover);
    text-decoration: underline;
  }

  &:active {
    color: var(--color-brandprimary-active);
    text-decoration: underline;
  }
}

.Link--secondary {
  &:link,
  &:visited {
    color: var(--color-neutral-900);
    text-decoration: underline;
  }

  &:hover,
  &:focus {
    color: var(--color-neutral-800);
  }

  &:active {
    color: var(--color-neutral-700);
  }
}

.Link--onDark {
  &:link,
  &:visited {
    color: var(--color-white);
    text-decoration: underline;
  }

  &:hover,
  &:focus {
    color: var(--color-neutral-100);
    text-decoration: underline;
  }

  &:active {
    color: var(--color-neutral-200);
  }
}

.Link--label {
  composes: u-typographyLabel1;

  &:link,
  &:visited {
    color: var(--color-neutral-900);
  }

  &:hover,
  &:focus {
    color: var(--color-neutral-800);
  }

  &:active {
    color: var(--color-neutral-700);
  }
}

.Link--bold {
  font-weight: var(--font-weight-medium);
}

/**
 * We should refactor the way we handle styles as the current setup makes it difficult
 * to have variations of primary/secondary/etc. styles that can have underlines
 * dynamically set.
 *
 * Until then this preserves the existing behaviour while giving us an option to remove
 * underlines from links.
 */
.Link--noDecoration {
  text-decoration: none;

  &:active,
  &:focus,
  &:hover,
  &:link,
  &:visited {
    text-decoration: none;
  }
}
