.FeaturedCard-innerCard {
  display: flex;
  flex-direction: row;
}

.FeaturedCard-imageWrapper {
  flex: 0 0 124px;
  position: relative;
}

.FeaturedCard-innerContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: var(--space-md);
}

.FeaturedCard-ctaAlign {
  > span {
    align-items: center;
    display: flex;
  }
}
