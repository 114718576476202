.Arrow {
  align-items: center;
  background-color: var(--color-white);
  border: 1px solid var(--color-neutral-400);
  border-radius: 50%;
  color: var(--color-neutral-400);
  cursor: pointer;
  display: flex;
  height: calc(var(--space-base) * 12);
  justify-content: center;
  padding: 0;
  width: calc(var(--space-base) * 12);

  &:disabled {
    background-color: var(--color-neutral-100);
    border-color: var(--color-neutral-100);
    cursor: not-allowed;
  }

  &:not(:disabled) {
    &:hover {
      border-color: var(--color-neutral-600);
      color: var(--color-neutral-600);
    }

    &:active {
      border-color: var(--color-neutral-800);
      color: var(--color-neutral-800);
    }

    &:focus {
      border-color: var(--color-primary-500);
      color: var(--color-primary-500);
    }
  }
}
