@import '~theme/typography.module.css';

.PrivateOfficePartTimeCard {
  background-color: var(--color-white);
  border: 1px solid var(--color-neutral-50);
  border-radius: var(--space-md);
  box-shadow: var(--shadow-resting);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  transition: box-shadow var(--animation-short);

  &:hover {
    box-shadow: var(--shadow-raised);
  }
}

.PrivateOfficePartTimeCard-left {
  border-bottom: 1px solid var(--color-neutral-100);
  border-right: 0;
  padding: var(--space-md);

  @media (--viewport-lg) {
    border-bottom: 0;
    border-right: 1px solid var(--color-neutral-100);
    height: 100%;
    padding: var(--space-lg);
  }
}

.PrivateOfficePartTimeCard-right {
  padding: var(--space-md);
  padding-top: 0;

  @media (--viewport-lg) {
    padding: var(--space-lg);
    padding-left: var(--spacing-sm);
  }
}

.PrivateOfficePartTimeCard-pricing {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;

  @media (--viewport-lg) {
    display: block;
  }
}

.PrivateOfficePartTimeCard-content {
  display: block;

  &::-webkit-details-marker {
    display: none;
  }
}

.PrivateOfficePartTimeCard--isHighlighted {
  border: 3px solid var(--color-brandsecondary-light);
}

.PrivateOfficePartTineCard-badges {
  align-items: center;
  display: grid;
  grid-gap: var(--space-sm);
  grid-template-areas: 'span recommendation-list-button price-plan-id';
  grid-template-columns: 1fr auto;
}

.PrivateOfficePartTimeCard-badge {
  align-items: center;
  background-color: var(--color-brandsecondary);
  border-radius: var(--space-md);
  color: var(--color-neutral-900);
  composes: u-typographyContent3;
  display: flex;
  font-size: var(--font-size-sm);
  padding: var(--space-xs) var(--space-sm);
  width: max-content;

  > span {
    margin-right: var(--space-sm);
  }

  > abbr {
    background-color: var(--color-neutral-900);
    border-radius: var(--space-xs);
    color: var(--color-white);
    margin-right: var(--space-xs);
    padding: 0 var(--space-xs);
    text-decoration: none;
    text-transform: capitalize;
  }
}

.PrivateOfficePartTimeCard-expandIcon {
  font: var(--font-style-content-1);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  line-height: var(--space-lg);

  details[open] &::after {
    content: 'expand_less';
    font-family: var(--font-family-icons);
    vertical-align: bottom;
  }

  details:not([open]) &::after {
    content: 'expand_more';
    font-family: var(--font-family-icons);
    vertical-align: bottom;
  }
}

.horizontalRule-hideOnMobile {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}
