@import '~theme/viewport.module.css';

.LayoutWrapper-formWrapper {
  @media (--viewport-md) {
    border: 1px solid var(--color-neutral-50);
    border-radius: var(--space-md);
    box-shadow: var(--shadow-resting);
    padding: var(--space-lg);
  }
}

.LayoutWrapper-noShadow {
  padding: var(--space-lg);
}

.LayoutWrapper-modalWrapper {
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  @media (--viewport-lg) {
    flex-direction: row-reverse;
  }
}

.LayoutWrapper-modalContentWrapper {
  background-color: var(--color-brandsecondary-background);
  display: flex;
  flex-direction: column;
  gap: var(--space-md);
  padding: var(--space-xxl);

  @media (--viewport-lg) {
    height: auto;
    width: 50%;
  }
}

.LayoutWrapper-modalFormWrapper {
  padding: var(--space-xxl) var(--space-md);

  @media (--viewport-md) {
    padding: var(--space-xxl);
  }

  @media (--viewport-lg) {
    width: 50%;
  }
}

.LayoutWrapper-showInSmallScreen {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.LayoutWrapper-showInLargeScreen {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}

.LayoutWrapper-contentBreak {
  @media (--viewport-md) {
    display: none;
  }
}
