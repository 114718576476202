@import '~theme/viewport.module.css';

.MiniMap-image {
  display: block;
  width: 100%;

  @media (--viewport-md) {
    max-width: 100%;
    width: auto;
  }
}

.MiniMap-picture {
  display: block;
}

.MiniMap-wrapper {
  border: none;
  border-radius: calc(var(--space-base) * 2);
  box-shadow: var(--shadow-resting);
  cursor: pointer;
  display: block;
  overflow: hidden;
  padding: 0;
  transition: var(--animation-short) linear box-shadow;

  &:hover,
  :focus {
    box-shadow: var(--shadow-raised);
  }

  @media (--viewport-md) {
    width: auto;
  }
}

.MiniMap-expandedMapContainer {
  --MiniMap-gridRowGapSize: 72px;

  height: calc(100vh - var(--MiniMap-gridRowGapSize));
}

.MiniMap-heading {
  /* create space for the close button in mobile view */
  margin: 12px calc(var(--space-base) * 14) 12px 12px;

  @media (--viewport-md) {
    margin: 12px;
  }
}
