@import '~theme/viewport.module.css';

.Form-datepicker {
  --rdp-day_button-height: var(--space-xxl);
  --rdp-day_button-width: var(--space-xxl);

  background-color: var(--color-white);
  margin: 0;
  width: max-content;
}

.Form-chevron {
  color: var(--color-neutral-500);
  margin-top: var(--space-sm);
}

.Form-day {
  &:hover {
    background-color: var(--color-neutral-50);
    border-radius: 50%;
    height: var(--rdp-day_button-height);
    width: var(--rdp-day_button-width);
  }
}

.Form-day--disabled {
  > button {
    color: var(--color-neutral-200);
  }

  &:hover {
    background-color: white;
    border: none;
  }
}

.Form-monthCaption {
  color: var(--color-neutral-900);
  font: var(--font-style-title-4);
  margin-bottom: var(--space-sm);
  margin-left: var(--space-sm);
  margin-top: var(--space-sm);
}

.Form-months {
  border: 1px solid var(--color-neutral-400);
  border-radius: var(--space-xs);
  position: relative;
}

.Form-nav {
  align-items: center;
  color: var(--color-neutral-900);
  display: flex;
  height: var(--rdp-nav-height);

  /* A hack to align the month navigation chevron with the month caption */
  padding-bottom: 16px;
  position: absolute;
  right: 0;
  top: 0;
}

.Form-day--selected {
  background-color: var(--color-brandsecondary);
  border-radius: 50%;
  color: var(--color-neutral-900);
  height: var(--rdp-day_button-height);
  width: var(--rdp-day_button-width);

  &:hover {
    background-color: var(--color-brandsecondary-hover);
    height: var(--rdp-day_button-height);
    width: var(--rdp-day_button-width);
  }
}

.Form-day--today {
  font-weight: var(--font-weight-medium);
  height: var(--rdp-day_button-height);
  width: var(--rdp-day_button-width);
}

.Form-weekday {
  color: var(--color-neutral-900);
  font-family: var(--font-family);
  font-size: var(--font-size-xxs);
  text-transform: uppercase;
}

.Form-formFields {
  display: flex;
  flex-direction: column;
  gap: var(--space-lg);

  @media (--viewport-md) {
    flex-direction: row;
    gap: var(--space-xl);
  }
}

.Form-formFieldsGroup {
  flex-grow: 2;
}

.Form-instantConfirmMsg {
  display: block;
  font: var(--font-style-content-2);
  margin-top: var(--space-sm);
  text-align: center;

  @media (--viewport-md) {
    display: inline;
    font: var(--font-style-content-3);
    font-weight: var(--font-weight-medium);
    margin: 0 var(--space-md);
  }
}
