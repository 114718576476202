@import '~theme/viewport.module.css';

.PassActions {
  --PassActions-secondaryNavHeight: 84px;

  /**
   * 1. N.B. CTAs are shown at the bottom of the page; this margin ensures
   *    space between those CTAs and the Similar Workspaces heading
   * 2. Align to the right
   * 3. Normally, with overflow: hidden;, box-shadows get clipped off. This hack
   *    works by padding the overflowing wrapper enough that the shadow can
   *    draw completely _inside_ the wrapper, then uses negative margins to
   *    expand the wrapper back out and align with the underlying grid
   * 4. The wrapper will stick just below the header and page nav. We want it to
   *    occupy the whole remaining height, worked out by subtracting the nav
   *    heights from 100vh
   * 5. Prevent the panel from looking too stretched (sausage-button-syndrome)
   * 6. Allow for vertical scrolling on shorter screens
   */
  margin-bottom: var(--space-xxl); /* 1 */

  @media (--viewport-lg) {
    float: right; /* 2 */
    margin: calc(var(--space-md) * -1); /* 3 */
    max-height: calc(
      100vh - var(--PassActions-secondaryNavHeight) + var(--NavBar-height)
    ); /* 4 */

    max-width: calc(var(--space-base) * 112); /* =448px, 5 */
    overflow-x: hidden; /* 6 */
    overflow-y: auto; /* 6 */
    padding: var(--space-md) var(--space-md) var(--space-xl) var(--space-md); /* 3 */
    position: sticky;
    top: calc(
      var(--PassActions-secondaryNavHeight) + var(--NavBar-height)
    ); /* 4 */

    width: 100%;
  }
}

.PassActions-panel {
  align-items: center;
  background-color: var(--color-white);
  bottom: 0;
  box-shadow: 0 -1px 16px 0 rgb(var(--color-neutral-900-rgb) / 10%);
  display: flex;
  left: 0;
  min-height: calc(var(--space-base) * 16);
  padding-bottom: env(
    safe-area-inset-bottom,
    0px
  ); /* Add padding, to avoid intersection with iOS/Android home indicators */

  position: fixed;
  right: 0;
  z-index: var(--z-index-drawer);

  @media (--viewport-lg) {
    border-radius: var(--space-md);
    bottom: auto;
    box-shadow: var(--shadow-resting);
    display: block;
    margin-bottom: var(--space-lg);
    padding: var(--space-lg);
    position: static;
  }
}
