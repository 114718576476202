.BasketItem {
  align-items: start;
  display: flex;
  gap: var(--space-sm);

  & abbr {
    text-decoration: none;
  }
}

.BasketItem-image {
  border-radius: var(--space-sm);
}

.BasketItem-deemphesized {
  color: var(--color-neutral-500);
}

.BasketItem-restore {
  margin-left: auto;

  &:hover {
    cursor: pointer;
  }
}

.BasketItem-loading {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 113px;
}
