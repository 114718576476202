@import '~theme/viewport.module.css';

.AdvisorRecommendationList {
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}

.AdvisorRecommendationList-actions {
  align-items: center;
  display: flex;
  gap: var(--space-sm);
}
