@import '~theme/viewport.module.css';

.CloseButton {
  background-color: transparent;
  border: 0;
  color: var(--color-neutral-500);
  cursor: pointer;
  line-height: var(--line-height-reset);
  padding: 0;
  position: absolute;
  right: var(--space-md);
  text-align: center;
  top: var(--space-md);
}

.CloseButton--fullScreen {
  position: fixed;
}

.CloseButton--mobileFullScreen {
  position: fixed;
  @media (--viewport-md) {
    position: absolute;
  }
}

.CloseButton-icon {
  display: block;
  font-size: var(--font-size-xl);
}
