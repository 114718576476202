.RecommendationListBuildModeBanner {
  align-items: center;
  background-color: var(--color-green-200);
  color: var(--color-neutral-900);
  display: flex;
  justify-content: space-between;
  padding: var(--space-sm);
}

.RecommendationListBuildModeBanner-exit {
  align-items: center;
  color: var(--color-neutral-900);

  &:hover,
  &:focus {
    color: var(--color-neutral-800);
    cursor: pointer;
    text-decoration: none;
  }

  &:active {
    color: var(--color-neutral-700);
  }
}
