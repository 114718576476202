.SelectorPills-list {
  --SelectorPills-height: 32px;

  display: flex;
  flex-wrap: wrap;
  gap: var(--space-sm);
  list-style: none;
  margin: 0;
  padding: 0;
}

.SelectorPills-list--horizontalScroll {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto visible;
  white-space: nowrap;

  @media (--viewport-md) {
    flex-wrap: wrap;
  }
}

.SelectorPills-buttonContainer {
  position: relative;

  label {
    align-items: center;
    background: var(--color-white);
    border: var(--SelectorPillItem--border);
    border-radius: var(--SelectorPillItem--borderRadius);
    color: var(--color-neutral-500);
    cursor: pointer;
    display: flex;
    font: var(--font-style-content-2);
    height: var(--SelectorPills-height);
    justify-content: center;
    padding: var(--SelectorPillItem--padding);
    transition-duration: var(--animation-short);
    transition-property: background-color;
    transition-timing-function: linear;
  }

  input {
    cursor: pointer;
    display: block;
    inset: 0;
    opacity: 0;
    position: absolute;
  }

  input[type='checkbox']:checked + label,
  input[type='radio']:checked + label {
    background: var(--color-brandsecondary);
    border: 1px var(--color-brandsecondary) solid;
  }

  @media (hover: hover) {
    /* Only use these hover styles when using a pointing device, ie. a mouse.
     * This prevents an issue where, on touchscreen devices, it's difficult to
     * tell if the pill is selected or not.
     */
    label:hover,
    input[type='checkbox']:hover + label,
    input[type='radio']:hover + label {
      background: var(--color-brandsecondary-hover);
      border: 1px var(--color-brandsecondary-hover) solid;
    }
  }

  input[type='checkbox']:active + label,
  input[type='radio']:active + label {
    background: var(--color-brandsecondary-active);
    border: 1px var(--color-brandsecondary-active) solid;
  }

  input[type='checkbox']:focus-visible + label,
  input[type='radio']:focus-visible + label {
    border: 2px var(--color-neutral-900) solid;
  }

  input[type='radio']:disabled + label,
  input[type='checkbox']:disabled + label {
    background: var(--color-neutral-200);
    border: 1px var(--color-neutral-200) solid;
    cursor: default;

    @media (hover: hover) {
      background: var(--color-neutral-200);
      border: 1px var(--color-neutral-200) solid;
    }
  }
}
