.FreeTrialBanner-wrapper {
  background-color: var(--color-neutral-900);
  color: var(--color-white);
  display: inline-block;
  font: var(--font-style-content-3);
  margin: 0;
  padding: calc(var(--space-base) * 3) var(--space-sm)
    calc(var(--space-base) * 3) var(--space-md);
  width: 100%;

  &:hover,
  &:focus {
    color: var(--color-neutral-50);
    text-decoration: none;
  }

  &:active {
    color: var(--color-neutral-100);
  }
}

.FreeTrialBanner-fakeLink {
  color: var(--color-brandprimary);
  font: var(--font-style-content-3);
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}
