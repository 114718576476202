@import '~theme/viewport.module.css';

.DropdownProducts-displayComponent {
  align-items: center;
  border: 1px solid var(--color-neutral-200);
  border-radius: var(--space-xs);
  display: grid;
  grid-template-areas: 'detail carat';
  grid-template-columns: 1fr auto;
  justify-items: stretch;
  padding: var(--space-sm);
  width: 100%;

  @media (--viewport-lg) {
    grid-template-areas:
      'detail detail carat'
      'capacity availability carat';
    grid-template-columns: auto 1fr auto;
  }
}

.DropdownProducts-displayContent {
  display: flex;
  flex-direction: column;
  font: var(--font-style-content-3);
  grid-area: detail;

  span:first-child {
    font-weight: var(--font-weight-medium);
  }

  span:nth-child(2) {
    display: none;
  }

  span:last-child {
    color: var(--color-neutral-500);
  }

  @media (--viewport-lg) {
    flex-direction: row;
    font-weight: var(--font-weight-medium);
    > span:nth-child(2) {
      display: block;
    }

    span:last-child {
      color: var(--color-neutral-900);
    }
  }
}

.DropdownProducts-capacity {
  display: none;
  font: var(--font-style-content-3);
  grid-area: capacity;
  margin-right: var(--space-md);
  @media (--viewport-lg) {
    display: block;
  }
}

.DropdownProducts-wrapper {
  flex: 1;
}

.DropdownProducts-carat {
  grid-area: carat;
}
