@import '~theme/typography.module.css';
@import '~theme/viewport.module.css';

.Button {
  --button-expected-height: calc(var(--space-base) * 13.5);
  --button-glow-brand-primary: 0 0 0 0 rgb(var(--color-brandprimary-rgb) / 40%);
  --button-glow-brand-primary-active: 0 20px 40px 0
    rgb(var(--color-brandprimary-rgb) / 40%);
  --button-compact-top-padding: var(--space-sm);
  --button-compact-bottom-padding: calc(var(--space-sm) - 2px);
  --button-compact-content-height: 21px;
  --button-compact-border-width: 2px;
  --button-compact-expected-height: calc(
    var(--button-compact-top-padding) + var(--button-compact-content-height) +
      var(--button-compact-bottom-padding) +
      (var(--button-compact-border-width) * 2)
  );
  --button-secondary-bg-disabled: transparent;
  --button-secondary-border-color-disabled: var(--color-neutral-100);
  --button-secondary-color-disabled: var(--color-neutral-300);
  --button-secondary-cursor-disabled: default;

  align-items: center;
  border: 2px solid;
  border-radius: calc(var(--button-expected-height) / 2);
  color: inherit;
  composes: u-typographyContent2;
  cursor: pointer;
  display: inline-flex;
  font-weight: var(--font-weight-medium);
  justify-content: center;
  min-height: var(--button-expected-height);

  /*
	* The -3 here is to optically center the uppercase text inside the button.
	* Because uppercase characters have no descenders, we need to account for the
	* empty space this leaves behind.
	*
	* Total button height is spacing.lg * 2.25 = 54px
	*/
  padding: 0 var(--space-lg);
  position: relative;
  text-align: center;
  transition-duration: var(--animation-short);
  transition-property: background-color, border-color, box-shadow, color;
  transition-timing-function: linear;
  vertical-align: middle;

  &:focus,
  &.sb__focus,
  &:hover,
  &.sb__hover,
  &:active,
  &.sb__active {
    /* Reset default <a> styles */
    text-decoration: none;
    transition-duration: var(--animation-short);
    transition-property: background-color, border-color, box-shadow, color;
    transition-timing-function: linear;
  }
}

.Button-afterSelector::after {
  --button-expected-height: calc(var(--space-base) * 13.5);
  --button-glow-brand-primary: 0 0 0 0 rgb(var(--color-brandprimary-rgb) / 40%);
  --button-glow-brand-primary-active: 0 20px 40px 0
    rgb(var(--color-brandprimary-rgb) / 40%);
  --button-compact-top-padding: var(--space-sm);
  --button-compact-bottom-padding: calc(var(--space-sm) - 2px);
  --button-compact-content-height: 21px;
  --button-compact-border-width: 2px;
  --button-compact-expected-height: calc(
    var(--button-compact-top-padding) + var(--button-compact-content-height) +
      var(--button-compact-bottom-padding) +
      (var(--button-compact-border-width) * 2)
  );
  --button-secondary-bg-disabled: transparent;
  --button-secondary-border-color-disabled: var(--color-neutral-100);
  --button-secondary-color-disabled: var(--color-neutral-300);
  --button-secondary-cursor-disabled: default;

  align-items: center;
  border: 2px solid;
  border-radius: calc(var(--button-expected-height) / 2);
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  font-weight: var(--font-weight-medium);
  justify-content: center;
  min-height: var(--button-expected-height);

  /*
    * The -3 here is to optically center the uppercase text inside the button.
    * Because uppercase characters have no descenders, we need to account for the
    * empty space this leaves behind.
    *
    * Total button height is spacing.lg * 2.25 = 54px
    */
  padding: 0 var(--space-lg);
  position: relative;
  text-align: center;
  transition-duration: var(--animation-short);
  transition-property: background-color, border-color, box-shadow, color;
  transition-timing-function: linear;
  vertical-align: middle;

  &:focus,
  &.sb__focus,
  &:hover,
  &.sb__hover,
  &:active,
  &.sb__active {
    /* Reset default <a> styles */
    text-decoration: none;
    transition-duration: var(--animation-short);
    transition-property: background-color, border-color, box-shadow, color;
    transition-timing-function: linear;
  }
}

.Button--primary {
  background-color: var(--color-brandprimary);
  border-color: var(--color-brandprimary);
  box-shadow: var(--button-glow-brand-primary);
  color: var(--color-white);

  &:focus,
  &.sb__focus {
    outline: none;
  }

  &:hover,
  &.sb__hover,
  &:focus,
  &.sb__focus {
    background-color: var(--color-brandprimary-hover);
    border-color: var(--color-brandprimary-hover);
    box-shadow: var(--button-glow-brand-primary-active);

    /* Reset default <a> styles */
    color: var(--color-white);
  }

  &:active,
  &.sb__active {
    background-color: var(--color-brandprimary-active);
    border-color: var(--color-brandprimary-active);
    box-shadow: var(--button-glow-brand-primary);

    /* Reset default <a> styles */
    color: var(--color-white);
  }

  &:disabled,
  &.isDisabled {
    background-color: var(--color-neutral-100);
    border-color: var(--color-neutral-100);
    color: var(--color-neutral-300);
    cursor: default;
    pointer-events: none;

    &:focus,
    &.sb__focus,
    &:hover,
    &.sb__hover,
    &:active,
    &.sb__active {
      box-shadow: none;
    }
  }
}

.Button--compact {
  border-radius: var(--button-compact-expected-height);
  min-height: var(--button-compact-expected-height);
  padding: var(--button-compact-top-padding) var(--space-md)
    var(--button-compact-bottom-padding);
}

.Button--block {
  width: 100%;
}

.Button--secondary {
  background-color: var(--color-transparent);
  border-color: var(--color-neutral-900);

  &:focus,
  &.sb__focus,
  &:hover,
  &.sb__hover,
  &:active,
  &.sb__active {
    /* Reset default <a> styles */
    background-color: var(--color-neutral-900);
    color: var(--color-white);
  }

  &:disabled,
  &.isDisabled {
    background-color: var(--button-secondary-bg-disabled);
    border-color: var(--button-secondary-border-color-disabled);
    color: var(--button-secondary-color-disabled);
    cursor: var(--button-secondary-cursor-disabled);
    pointer-events: none;
  }
}

.Button-afterSelector--secondary::after {
  background-color: var(--color-transparent);
  border-color: var(--color-neutral-900);
  cursor: var(--button-secondary-cursor-disabled);

  &:focus,
  &.sb__focus,
  &:hover,
  &.sb__hover,
  &:active,
  &.sb__active {
    /* Reset default <a> styles */
    background-color: var(--color-neutral-900);
    color: var(--color-white);
  }

  &:disabled,
  &.isDisabled {
    background-color: var(--button-secondary-bg-disabled);
    border-color: var(--button-secondary-border-color-disabled);
    color: var(--button-secondary-color-disabled);
    pointer-events: none;
  }
}

.Button--secondaryWhite {
  background-color: var(--color-transparent);
  border-color: var(--color-white);
  color: var(--color-white);

  &:focus,
  &.sb__focus,
  &:hover,
  &.sb__hover,
  &:active,
  &.sb__active {
    /* Reset default <a> styles */
    background-color: var(--color-white);
    color: var(--color-neutral-900);
  }

  &:disabled,
  &.isDisabled {
    background-color: var(--button-secondary-bg-disabled);
    border-color: var(--button-secondary-border-color-disabled);
    color: var(--button-secondary-color-disabled);
    cursor: var(--button-secondary-cursor-disabled);
    pointer-events: none;
  }
}

.Button--white {
  background-color: var(--color-white);
  border-color: var(--color-neutral-900);

  &:focus,
  &.sb__focus,
  &:hover,
  &.sb__hover,
  &:active,
  &.sb__active {
    /* Reset default <a> styles */
    background-color: var(--color-neutral-900);
    color: var(--color-white);
  }

  &:disabled,
  &.isDisabled {
    background-color: var(--button-secondary-bg-disabled);
    border-color: var(--button-secondary-border-color-disabled);
    color: var(--button-secondary-color-disabled);
    cursor: var(--button-secondary-cursor-disabled);
    pointer-events: none;
  }
}

.Button--smallFullWidth {
  /*
  * Because of the way Grid shrink wraps elements, having width of 100% means it never actually
  * goes full screen, it 'expands' to 100% of it's shrink wrapped container, which is just the
  * same size as it is on desktop. Doing this explicitly says:
  * "At mobile, be the full width minus the gutters that exist on all mobile viewports"
  * This means we can center items on tablet view without having to create wrapper containers
  * and our Grid can work as intended.
  */
  width: calc(100vw - var(--space-xl));

  @media (--viewport-md) {
    width: auto;
  }
}

.Button--smallIconOnly {
  background-color: var(--color-transparent);
  border-width: 0;
  padding: var(--space-sm); /* Increase the touch target a smidge. */

  @media (--viewport-md) {
    border-width: 2px;
    padding: var(--space-md) var(--space-xxl);
  }
}

.Button-spinner--hidden {
  visibility: hidden;
}

.Button-spinnerWrapper {
  align-items: center;
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
