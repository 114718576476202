@import '~theme/viewport.module.css';

.PassListing-content {
  position: relative;
}

.PassListing-imageCarousel {
  margin-left: calc(var(--space-md) * -1);
  margin-right: calc(var(--space-md) * -1);
  position: relative;

  @media (--viewport-md) {
    margin-left: 0;
    margin-right: 0;
  }
}

.PassListing-badge {
  left: var(--space-md);
  position: absolute;
  top: var(--space-md);
}
