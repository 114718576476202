.AdvisorPhoto--large {
  margin-right: var(--space-sm);
}

.AdvisorPhoto--medium {
  margin-right: var(--space-md);
}

.AdvisorPhoto--small {
  margin-right: var(--space-sm);
  vertical-align: bottom;
  width: var(--space-xxl);
}
