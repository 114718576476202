@import '~theme/viewport.module.css';
@import '~theme/typography.module.css';

.PrivateOfficeFullTimeCard {
  background-color: var(--color-white);
  border: 1px solid var(--color-neutral-50);
  border-radius: var(--space-md);
  box-shadow: var(--shadow-resting);
  cursor: pointer;
  overflow: hidden;
  transition: box-shadow var(--animation-short);

  &:hover {
    box-shadow: var(--shadow-raised);
  }
}

.PrivateOfficeFullTimeCard-left {
  border-bottom: 1px solid var(--color-neutral-100);
  border-right: 0;
  padding: var(--space-md);

  @media (--viewport-lg) {
    border-bottom: 0;
    border-right: 1px solid var(--color-neutral-100);
    height: 100%;
    padding: var(--space-md) var(--space-lg) var(--space-lg) var(--space-lg);
  }
}

.PrivateOfficeFullTimeCard--hasSpecialOffer {
  border-bottom: 1px solid var(--color-brandsecondary-light);

  @media (--viewport-lg) {
    border-bottom: 1px solid var(--color-neutral-100);
  }
}

.PrivateOfficeFullTimeCard--isHighlighted {
  border: 3px solid var(--color-brandsecondary-light);
}

.PrivateOfficeFullTimeCard-content {
  display: block;

  &::-webkit-details-marker {
    display: none;
  }
}

.PrivateOfficeFullTimeCard-badges {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.PrivateOfficeFullTimeCard-expandIcon {
  font: var(--font-style-content-1);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  line-height: var(--space-lg);

  details[open] &::after {
    content: 'expand_less';
    font-family: var(--font-family-icons);
    vertical-align: bottom;
  }

  details:not([open]) &::after {
    content: 'expand_more';
    font-family: var(--font-family-icons);
    vertical-align: bottom;
  }
}

.PrivateOfficeFullTimeCard-badgeWrapper {
  grid-area: badge;
}

.PrivateOfficeFullTimeCard-withBadge {
  display: grid;
  grid-gap: var(--space-sm);
  grid-template-areas: 'recommendation-list-button recommendation-list-button' 'badge shortlist-button' 'span price-plan-id';

  @media (--viewport-lg) {
    grid-template-areas: 'badge span recommendation-list-button price-plan-id shortlist-button';
    grid-template-columns: auto 1fr auto auto;
  }
}

.PrivateOfficeFullTimeCard-specialOffer {
  background-color: var(--color-brandsecondary-light);
  padding: var(--space-sm);

  @media (--viewport-lg) {
    border-radius: var(--space-md);
    padding: var(--space-xs) var(--space-sm);
    width: fit-content;
  }
}

.PrivateOfficeFullTimeCard-right {
  padding: var(--space-md);
  padding-top: 0;

  @media (--viewport-lg) {
    padding: var(--space-lg);
    padding-left: var(--space-sm);
  }
}

.PrivateOfficeFullTimeCard-pricing {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;

  @media (--viewport-lg) {
    display: block;
  }
}

.u-displayAboveMd {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}

.horizontalRule-hideOnMobile {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}
