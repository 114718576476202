.CopyLink {
  align-items: center;
  background-color: var(--color-neutral-50);
  border: 1px solid var(--color-neutral-100);
  border-radius: 26px; /* Half the height, as measured in browser */
  display: flex;
  padding: var(--space-xs);
}

.CopyLink-link {
  flex: 1;
  overflow: hidden;
  padding: var(--space-sm);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.CopyLink-button {
  flex: 0;
  white-space: nowrap;
}
