.SwitchButton {
  align-items: center;
  color: var(--color-neutral-800);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font: var(--font-style-title-3);
  font-size: var(--font-size-sm);
}

.SwitchButton--disabled {
  cursor: default;
}

.SwitchButton-button {
  background: var(--color-neutral-100);
}

.SwitchButton-button--on {
  background: var(--color-brandsecondary);
}
