@import '~theme/viewport.module.css';

.LegalLinks {
  display: flex;
  flex-direction: column;

  @media (--viewport-lg) {
    align-items: center;
    flex-direction: row;
    gap: 14px;
    justify-content: space-between;
  }
}

.LegalLinks-legal {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 14px;
  text-align: center;

  @media (--viewport-md) {
    align-items: end;
    flex-direction: row;
    text-align: left;
  }

  @media (--viewport-lg) {
    margin-bottom: 0;
  }
}

.LegalLinks-social {
  display: flex;
  gap: 14px;
  justify-content: center;
}
