.AccountIconLetter {
  background-color: var(--color-white);
  border: 2px solid var(--color-neutral-900);
  border-radius: 50%;
  box-sizing: border-box;
  color: var(--color-neutral-900);
  display: inline-block;
  height: var(--AccountIconLetter-size);
  margin: 3px;
  overflow: hidden;
  position: relative;
  transition: background-color 0.3s ease;
  vertical-align: middle;
  width: var(--AccountIconLetter-size);
}

.AccountIconLetter--hover {
  &:hover {
    background-color: var(--color-brandprimary);
    border-color: var(--color-brandprimary);
    color: var(--color-white);
    cursor: pointer;
  }
}

.AccountIconLetter-letter {
  color: inherit;
  display: block;
  font-family: var(--font-family);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  height: 100%;
  line-height: var(--AccountIconLetter-lineHeight);
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}
