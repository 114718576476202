@import '~theme/typography.module.css';
@import '~theme/viewport.module.css';

.Offices-filterHeading {
  padding: var(--space-md);
  padding-right: var(--space-lg);
}

.Offices--filterDisplay {
  align-items: center;
  background-color: var(--color-white);
  border: 1px solid var(--color-neutral-200);
  border-radius: var(--space-xs);
  color: var(--color-neutral-900);
  composes: u-typographyContent3;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: var(--space-sm);
}

.Offices-filterItem {
  border-right: 1px solid var(--color-neutral-200);
  display: flex;
  flex: 2;
  flex-direction: column;
  height: 44px;
  justify-content: center;
  margin-right: var(--space-sm);
  padding-right: var(--space-sm);
  text-align: left;

  &:last-child {
    border-right: none;
  }
}

.Offices-filterItemIcon {
  flex: 1;
  margin-right: 0;
  padding-right: 0;
  text-align: center;
}

.Offices--datesSelected {
  display: flex;
  gap: var(--space-xs);
}

.Offices--displayDate {
  text-decoration: none;
}

.Offices--occupancy {
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
  height: 100%;
}

.Offices--label {
  color: var(--color-neutral-900);
}

.Offices--dates {
  display: flex;
  gap: var(--space-md);
}

.Offices--date {
  text-align: center;
}

.Offices--checkboxWrapper {
  @media (--viewport-sm) {
    align-items: center;
    background-color: var(--color-white);
    border: 1px solid var(--color-neutral-200);
    border-radius: var(--space-xs);
    display: flex;
    height: 100%;
    padding: var(--space-sm);
  }
}

.u-displayAboveLg {
  display: none;

  @media (--viewport-lg) {
    align-items: center;
    display: flex;
  }
}

.u-displayBelowLg {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}
