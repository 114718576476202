@import '~theme/viewport.module.css';

.BuildingTable {
  --BuildingTable-border: 0px solid var(--color-neutral-25);
  --BuildingTable-headerBorder: 2px solid var(--color-neutral-25);
  --BuildingTable-yAxisHeaderWidth: 96px;

  border-collapse: separate;
  color: var(--color-neutral-900);
  table-layout: fixed;
}

.BuildingTable-xAxisHeader {
  background-color: var(--color-white);
}

.BuildingTable-cornerHeaderItem {
  display: none;

  @media (--viewport-md) {
    background-color: var(--color-white);
    border: var(--BuildingTable-border);
    border-bottom: var(--BuildingTable-headerBorder);
    border-collapse: separate;
    display: table-cell;
    font-weight: var(--font-weight-medium);
    padding: var(--space-md);
    position: sticky;
    top: 0;
    z-index: var(--z-index-nav);

    &:first-child {
      border-right: var(--BuildingTable-headerBorder);

      /* The first cell of the x axis visually appears as if it should be in the
      y axis so this ensures the sticky scroll behaviour makes sense */
      left: 0;
      z-index: calc(var(--z-index-nav) + 1);
    }
  }
}

.BuildingTable-xAxisHeaderItem {
  background-color: var(--color-white);
  border: var(--BuildingTable-border);
  border-bottom: var(--BuildingTable-headerBorder);
  border-collapse: separate;
  font-weight: var(--font-weight-medium);
  padding: var(--space-md);
  position: sticky;
  top: 0;
  z-index: var(--z-index-nav);

  &.isHighlighted {
    background-color: var(--color-brandsecondary-background);
  }
}

.BuildingTable-yAxisHeader {
  background-color: var(--color-white);
}

.BuildingTable-yAxisHeaderItem {
  display: none;

  @media (--viewport-md) {
    background-color: var(--color-white);
    border: var(--BuildingTable-border);
    border-right: var(--BuildingTable-headerBorder);
    display: table-cell;
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-light);
    left: 0;
    padding: var(--space-md);
    position: sticky;
    text-align: left;
    width: var(--BuildingTable-yAxisHeaderWidth);
    z-index: calc(var(--z-index-nav) - 1);

    &.isHighlighted {
      background-color: var(--color-brandsecondary-light);
    }

    &.isString {
      font-weight: var(--font-weight-medium);
    }
  }
}

.BuildingTable-cell {
  background-color: var(--color-white);
  border: var(--BuildingTable-border);
  min-width: calc(100vw - var(--BuildingTable-yAxisHeaderWidth));
  padding: var(--space-md);
  text-align: center;

  &.isHighlighted {
    background-color: var(--color-brandsecondary-background);
  }

  @media (--viewport-md) {
    min-width: auto;
    width: 390px;
  }
}

.BuildingTable-cellTitle {
  font: var(--font-style-content-3);
  font-weight: var(--font-weight-medium);

  @media (--viewport-md) {
    display: none;
  }
}

.BuildingTable-groupedRow {
  background-color: var(--color-brandsecondary-background);
}

.BuildingTable-groupedHeaderItem {
  background-color: var(--color-brandsecondary-background);
  border: var(--BuildingTable-border);
  border-right: var(--BuildingTable-headerBorder);
  padding: var(--space-md);
  text-align: left;

  &.isHighlighted {
    background-color: var(--color-brandsecondary-light);
  }
}

.BuildingTable-groupedHeaderWithSubheader {
  display: none;

  @media (--viewport-md) {
    display: table-cell;
  }
}

.BuildingTable-groupedCell {
  background-color: var(--color-brandsecondary-background);
  padding: var(--space-md);
  text-align: center;

  &.isHighlighted {
    background-color: var(--color-brandsecondary-light);
  }
}
