@import '~theme/typography.module.css';

.Badge {
  align-items: center;
  background-color: var(--Badge--backgroundColor);
  border-radius: var(--space-md);
  color: var(--Badge--textColor);
  display: flex;
  flex-wrap: wrap;
  margin-right: var(--Badge-margin);
  width: max-content;
}

.Badge-content {
  composes: u-typographySmallLabel;
  height: var(--space-lg);
  padding: var(--space-xs) var(--space-sm);
  position: relative;
  top: 1px;
}

.Badge--marginSmall {
  margin-right: var(--space-sm);
}

.Badge--marginExtraSmall {
  margin-right: var(--space-xs);
}

.Badge--borderedBadge {
  border: 1px solid var(--Badge--borderColor);
  border-radius: var(--space-md);
  padding: var(--space-xs) calc(var(--space-sm) - 1px)
    calc(var(--space-xs) + 1px);
}

.Badge--small {
  height: calc(var(--space-md) + 2px);
  padding: 1px var(--space-xs);
}

.Badge-icon {
  font-size: var(--font-size-xxs);
  margin-left: var(--space-xs);
  position: relative;
  right: -4px;
}

.Badge--fullWidth {
  width: 100%;
}
