@import '~theme/viewport.module.css';

/**
 * This is a CSS module - its class names are not available globally, but they
 * may be used in a `composes` property declaration.
 *
 * You can import it from other components.
 */

.u-typographyHero1 {
  font: var(--font-style-hero-1);
}

.u-typographyHero2 {
  font: var(--font-style-hero-2);
}

.u-typographyTitle1 {
  font: var(--font-style-title-1);
}

.u-typographyTitle2 {
  font: var(--font-style-title-2);
}

.u-typographyTitle3 {
  font: var(--font-style-title-3);
}

.u-typographyTitle4 {
  font: var(--font-style-title-4);
}

.u-typographyContent1 {
  font: var(--font-style-content-1);
}

.u-typographyContent2 {
  font: var(--font-style-content-2);
}

.u-typographyContent3 {
  font: var(--font-style-content-3);
}

.u-typographyLabel1 {
  font-family: var(--font-family);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-lineheight-lg);
}

.u-typographyLabel2 {
  font-family: var(--font-family);
  font-size: var(--font-size-xxs);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-lineheight-md);
}

.u-typographySmallLabel {
  font-family: var(--font-family);
  font-size: var(--font-size-xxs);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-lineheight-md);
  text-transform: uppercase;
}

.material-icon,
.material-icon-pseudoelement--before::before,
.material-icon-pseudoelement--after::after {
  direction: ltr;
  display: inline-block;
  font-family: var(--font-family-icons);
  -webkit-font-feature-settings: 'liga';
  font-size: var(--space-lg);
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
}
