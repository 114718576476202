@import '~theme/viewport.module.css';

.Location-nearbyTransitStops {
  columns: 1;

  > li {
    break-inside: avoid;
  }

  @media (--viewport-md) {
    columns: 3;
  }

  @media (--viewport-lg) {
    columns: 4;
  }

  @media (--viewport-xl) {
    columns: 5;
  }
}

.Location-map {
  border-radius: var(--space-md);
  height: 390px;
  position: relative;
}

.Location-mapButton {
  bottom: 24px;
  position: absolute;
  right: 12px;
}

.Location-expandedMap {
  height: calc(100vh - 60px);

  @media (--viewport-md) {
    height: calc(100vh - 60px);
  }

  @media (--viewport-lg) {
    height: calc(100vh - 96px);
  }
}
