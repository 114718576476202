@import '~theme/viewport.module.css';

.NumberRangeField-wrapper {
  display: flex;
  flex-direction: column;
}

.NumberRangeField-inputFieldWrapper {
  display: flex;
  margin: 0 auto;

  @media (--viewport-lg) {
    max-width: calc(var(--space-base) * 84);
  }
}

.NumberRangeField-input {
  flex: 1;
  width: calc(var(--space-base) * 36); /* 144px */
}

.NumberRangeField-hyphen {
  margin: var(--space-xl) var(--space-md) 0;
}
