@keyframes alert-toast-pop {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }

  40% {
    transform: scale(1.05);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.AlertToast-closeButton {
  background: none;
  color: inherit;
  cursor: pointer;
  flex: 0;
}

.AlertToast-closeIcon {
  display: block;
  font-size: var(--space-lg);
}

.AlertToast-content {
  flex: 1;
  margin-right: var(--space-sm);
}

.AlertToast-icon {
  display: block;
  flex: 0;
  font-size: var(--space-lg);
  margin-right: var(--space-sm);
}

.AlertToast {
  align-items: center;
  animation: alert-toast-pop var(--animation-medium) ease-in-out;
  display: flex;
  margin: 0 auto;
  max-width: var(--grid-maxWidth);
  padding: var(--space-md);
  pointer-events: auto;
  width: 100%;
}

.AlertToast--error {
  background-color: var(--color-error);
  color: var(--color-white);
}

.AlertToast--success {
  background-color: var(--color-green-200);
  color: var(--color-neutral-900);
}
