.SearchAsIMoveToggle {
  align-items: center;
  background: var(--color-white);
  border-radius: var(--space-sm);
  box-shadow: var(--shadow-raised);
  cursor: pointer;
  display: flex;
  font: var(--font-style-content-2);
  font-family: inherit;
  font-weight: var(--font-weight-medium);
  left: 50%;
  padding: var(--space-sm) var(--space-md);
  position: absolute;
  text-align: center;
  top: var(--space-md);
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1;
}

.SearchAsIMoveToggle--noResults {
  background: var(--color-error);
  color: var(--color-white);
  cursor: default;
  display: flex;
}

.SearchAsIMoveToggle--redoSearch {
  background: var(--color-brandsecondary);
  color: var(--color-neutral-900);
  cursor: pointer;
  display: flex;
}

.SearchAsIMoveToggle-checkboxType {
  cursor: pointer;
  visibility: hidden;
}

.SearchAsIMoveToggle-checkbox {
  border-radius: var(--space-base);
  cursor: pointer;
  height: var(--space-lg);
  margin-right: var(--space-sm);
  text-align: left;
  width: var(--space-lg);
}

.SearchAsIMoveToggle-checkbox.is-checked {
  background-color: var(--color-brandsecondary);
  border: solid 1px var(--color-brandsecondary);
}

.SearchAsIMoveToggle-checkbox:not(.is-checked) {
  background-color: var(--color-white);
  border: solid 1px var(--color-neutral-200);
}

.SearchAsIMoveToggle-checkedIcon {
  font-size: var(--font-size-lg);
  height: 11px;
  margin-left: calc(var(--space-base) / 4);
  width: var(--space-md);
}

.SearchAsIMoveToggle-redoSearchLabel {
  align-items: center;
  display: flex;
}

.SearchAsIMoveToggle-labelSpacing {
  margin-right: var(--space-xs);
}
