/**
 * NOTE: This is **NOT** a CSS module. Instead, it exposes custom properties
 * that are available globally on the page, and handled by the browser.
 *
 * You should not declare anything that is not a CSS Custom Property in this 
 * file. Class names and the like should always be declared in a module.
 */

:root {
  --animation-short: 0.2s;
  --animation-medium: 0.35s;
  --animation-long: 0.5s;
}
