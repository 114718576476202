@import '~theme/viewport.module.css';

.Centered {
  text-align: center;
}

.Centered--belowLg {
  text-align: center;

  @media (--viewport-lg) {
    text-align: left;
  }
}

.Centered--aboveLg {
  @media (--viewport-lg) {
    text-align: center;
  }
}
