@import '~theme/viewport.module.css';

.ImageCarousel {
  --ImageCarousel-visibleItems: 2;

  align-items: center;
  display: flex;
  height: calc(var(--space-base) * 20);

  @media (--viewport-sm) {
    height: calc(var(--space-base) * 27.5); /* 110px */
  }

  @media (--viewport-md) {
    --ImageCarousel-visibleItems: 4;

    height: calc(var(--space-base) * 18); /* 72px */
  }

  @media (--viewport-lg) {
    --ImageCarousel-visibleItems: 5;
  }
}

.ImageCarousel-arrowWrapper {
  display: none;

  @media (--viewport-md) {
    display: flex;
  }
}

.ImageCarousel-images {
  align-items: center;
  display: grid;
  grid-auto-columns: calc(
    (100% - 1 * var(--space-md)) / var(--ImageCarousel-visibleItems)
  );
  grid-auto-flow: column;
  grid-gap: var(--space-md);
  margin: var(--space-sm);
  overflow: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  width: 100vw;

  @media (--viewport-md) {
    grid-auto-columns: calc(
      (100% - 4 * var(--space-md)) / var(--ImageCarousel-visibleItems)
    );
    overflow: hidden;
    width: 100%;
  }

  @media (--viewport-lg) {
    grid-auto-columns: calc(
      (100% - 5 * var(--space-md)) / var(--ImageCarousel-visibleItems)
    );
  }
}

.ImageCarousel-figure {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  scroll-snap-align: start;
}

.ImageCarousel-image {
  width: 50%;
}

.ImageCarousel-image--withCustomWidth {
  width: var(--Image-width);
}
