@import '~theme/viewport.module.css';

.UsefulLinks {
  columns: 1;
  text-align: center;

  @media (--viewport-md) {
    columns: 2;
    text-align: left;
  }

  @media (--viewport-lg) {
    columns: 5;
  }
}

.UsefulLinks-section {
  /*
   * 1. Ensure that at larger breakpoints, the multi-column layout doesn't try and
   *    stack columns. Ensures we always keep exactly 5 columns at this breakpoint.
   */

  break-inside: avoid;
  margin-bottom: var(--space-lg);

  &:last-child {
    margin-bottom: 0;
  }

  @media (--viewport-lg) {
    break-after: column; /* 1 */
    margin-bottom: 0;
  }
}

.UsefulLinks-link {
  margin-bottom: var(--space-md);

  &:last-child {
    margin-bottom: 0;
  }
}
