/**
 * NOTE: This is **NOT** a CSS module. Instead, it exposes custom properties
 * that are available globally on the page, and handled by the browser.
 *
 * You should not declare anything that is not a CSS Custom Property in this
 * file. Class names and the like should always be declared in a module.
 */

/* Declare some color values locally to be imported to root as global style variables */

:root {
  /*
   * Base color palette.
   * Avoid using these directly in components. Use functional colors instead.
   */

  --color-blue-50: #a4fdff;
  --color-blue-100: #4bf4ff;
  --color-blue-100-rgb: 75 244 255;
  --color-blue-200: #33e5f0;
  --color-blue-300: #00d4df;
  --color-blue-400: #00a4b0;
  --color-blue-500: #00838f;
  --color-blue-600: undefined;
  --color-blue-700: undefined;
  --color-blue-800: undefined;
  --color-blue-900: undefined;
  --color-blue-pale: #dbfdff;
  --color-green-50: #d0fff6;
  --color-green-100: #76fde7;
  --color-green-200: #00f2c4;
  --color-green-300: #00cda1;
  --color-green-400: #00a980;
  --color-green-500: #008761;
  --color-green-600: #219653;
  --color-green-700: undefined;
  --color-green-800: undefined;
  --color-green-900: undefined;
  --color-pink-50: #fff2f7;
  --color-pink-100: #ffd9e9;
  --color-pink-200: #ffbad7;
  --color-pink-300: #ff99c3;
  --color-pink-400: #ff377a;
  --color-pink-400-rgb: 255 55 122;
  --color-pink-500: #e70f67;
  --color-pink-600: #d20057;
  --color-pink-700: #bc0047;
  --color-pink-800: undefined;
  --color-pink-900: undefined;
  --color-magenta-50: #f7f2f6;
  --color-magenta-100: #e6d9e5;
  --color-magenta-200: #d4c1d3;
  --color-magenta-300: #c3a9c1;
  --color-magenta-400: #a17a9f;
  --color-magenta-500: #90638e;
  --color-magenta-600: #804d7d;
  --color-magenta-700: #6f366d;
  --color-magenta-800: #4c0649;
  --color-magenta-900: undefined;
  --color-purple-50: #f9f1ff;
  --color-purple-100: #eed7ff;
  --color-purple-200: #e4c6ff;
  --color-purple-300: #d0a3ff;
  --color-purple-400: #b06cff;
  --color-purple-500: #9842ff;
  --color-purple-600: #8927ff;
  --color-purple-700: #7200ff;
  --color-purple-800: undefined;
  --color-purple-900: undefined;
  --color-red-50: #ffeaf1;
  --color-red-100: undefined;
  --color-red-200: undefined;
  --color-red-300: undefined;
  --color-red-400: undefined;
  --color-red-500: #ee000a;
  --color-red-500-rgb: 238 0 10;
  --color-red-600: #d70000;
  --color-red-700: #c00000;
  --color-red-800: undefined;
  --color-red-900: undefined;

  /*
   * Functional/named colors.
   * Use these in your components.
   */

  --color-brandprimary: var(--color-pink-400); /* #FF377A */
  --color-brandprimary-hover: var(--color-pink-500); /* #E70F67 */
  --color-brandprimary-active: var(--color-pink-600); /* #D20057 */
  --color-brandprimary-rgb: var(--color-pink-400-rgb);
  --color-brandsecondary: var(--color-blue-100); /* #4BF4FF */
  --color-brandsecondary-hover: var(--color-blue-200); /* #33E5F0 */
  --color-brandsecondary-active: var(--color-blue-300); /* #00D4DF */
  --color-brandsecondary-rgb: var(--color-blue-100-rgb);
  --color-brandsecondary-light: var(--color-blue-50); /* #A4FDFF */
  --color-brandsecondary-background: var(--color-blue-pale); /* #DBFDFF */
  --color-error: var(--color-red-500); /* #EE000A */
  --color-error-hover: var(--color-red-600); /* #D70000 */
  --color-error-active: var(--color-red-700); /* #C00000 */
  --color-error-rgb: var(--color-red-500-rgb);
  --color-success: var(--color-green-500); /* #008761 */
  --color-ghostlogin: var(--color-green-200); /* #00F2C4 */
  --color-neutral-25: #f9f9f9;
  --color-neutral-50: #f2f3f5;
  --color-neutral-100: #e2e5ea;
  --color-neutral-200: #d2d8df;
  --color-neutral-300: #a9b2bb;
  --color-neutral-400: #8b93a4;
  --color-neutral-500: #6e768d;
  --color-neutral-600: #606882;
  --color-neutral-700: #525a77;
  --color-neutral-800: #454c6d;
  --color-neutral-900: #050a3a;
  --color-neutral-900-rgb: 5 10 58;
  --color-black: #000;
  --color-black-rgb: 0 0 0;
  --color-white: #fff;
  --color-white-rgb: 255 255 255;
  --color-transparent: transparent;
  --color-gradient-blue: #eefbfe;
  --color-gradient-pink: #feeff8;
  --color-gradient-pinkblue: linear-gradient(#feeff8, #eefbfe);
  --color-gradient-pinkblue-horizontal: linear-gradient(
    90deg,
    #feeff8 50%,
    #eefbfe 75%
  );
  --color-gradient-bluepink: linear-gradient(#eefbfe, #feeff8);
  --color-gradient-bluepink-horizontal: linear-gradient(
    90deg,
    #eefbfe 50%,
    #feeff8 75%
  );
  --color-gradient-pinkblue-diagonal: linear-gradient(
    to bottom right,
    var(--color-gradient-pink),
    var(--color-gradient-blue)
  );
}
