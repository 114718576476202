/**
 * NOTE: This is **NOT** a CSS module. Instead, it exposes custom properties
 * that are available globally on the page, and handled by the browser.
 *
 * You should not declare anything that is not a CSS Custom Property in this
 * file. Class names and the like should always be declared in a module.
 */

/**
 * DEPRECATION NOTICE
 *
 * We are moving our styling tooling to CSS modules. This file will be removed
 * in the future.
 *
 * font.css is the source of truth for all font style variables.
 */

:root {
  --font-family: 'Simplon Norm', sans-serif;
  --font-family-icons: 'Material Icons';
  --font-weight-light: 300;
  --font-weight-medium: 500;
  --font-size-xxxl: 58px;
  --font-size-xxl: 42px;
  --font-size-xl: 28px;
  --font-size-lg: 21px;
  --font-size-md: 18px;
  --font-size-sm: 16px;
  --font-size-xs: 14px;
  --font-size-xxs: 12px;
  --font-lineheight-xxl: 2;
  --font-lineheight-xl: 1.6666;
  --font-lineheight-lg: 1.5;
  --font-lineheight-md: 1.3333;
  --font-lineheight-sm: 1.2858;
  --font-lineheight-xs: 1.1429;
  --font-lineheight-xxs: 1.1034;
  --font-lineheight-reset: 1;
  --font-style-hero-1: var(--font-weight-medium) var(--font-size-xxl) /
    var(--font-lineheight-xs) var(--font-family);
  --font-style-hero-2: var(--font-weight-medium) var(--font-size-xxl) /
    var(--font-lineheight-xs) var(--font-family);
  --font-style-title-1: var(--font-weight-medium) var(--font-size-xl) /
    var(--font-lineheight-sm) var(--font-family);
  --font-style-title-2: var(--font-weight-light) var(--font-size-xl) /
    var(--font-lineheight-sm) var(--font-family);
  --font-style-title-3: var(--font-weight-medium) var(--font-size-lg) /
    var(--font-lineheight-md) var(--font-family);
  --font-style-title-4: var(--font-weight-medium) var(--font-size-md) /
    var(--font-lineheight-md) var(--font-family);
  --font-style-content-1: var(--font-weight-light) var(--font-size-md) /
    var(--font-lineheight-xl) var(--font-family);
  --font-style-content-2: var(--font-weight-light) var(--font-size-sm) /
    var(--font-lineheight-lg) var(--font-family);
  --font-style-content-3: var(--font-weight-light) var(--font-size-xs) /
    var(--font-lineheight-lg) var(--font-family);

  @media (--viewport-lg) {
    /* The hero-1 text size increases at larger breakpoints */
    --font-style-hero-1: var(--font-weight-medium) var(--font-size-xxxl) /
      var(--font-lineheight-xxs) var(--font-family);
  }
}
