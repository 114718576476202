@import '~theme/viewport.module.css';

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ModalCloseIcon {
  display: block;
  font-size: var(--font-size-xl);
}

.ModalUnmanaged {
  animation: fade-in 200ms ease;
  position: relative;
  z-index: var(--z-index-modal);
}

.ModalBackground {
  background-color: rgb(var(--color-black-rgb) / 85%);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--z-index-modal);
}

.ModalCloseButton {
  background-color: transparent;
  border: 0;
  color: var(--color-neutral-500);
  cursor: pointer;
  line-height: var(--font-lineheight-reset);
  padding: 0;
  position: fixed;
  right: var(--space-md);
  text-align: center;
  top: var(--space-md);
  z-index: var(--z-index-modal);

  @media (--viewport-md) {
    position: absolute;
  }
}

.ModalCloseButton--fullScreen {
  position: fixed;
}

.ModalContainer {
  background-color: var(--color-white);
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  z-index: var(--z-index-modal);
}

.ModalContainer--centerContent {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ModalContainer--dialog {
  /* Note: modals below the md breakpoint are always full screen */
  border-radius: 0;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  @media (--viewport-md) {
    border-radius: var(--space-md);
    height: auto;
    max-height: 85%;
    max-width: 900px;
    width: 80%;
  }
}

.ModalContainer--positionTop {
  top: 10%;
  transform: translate(-50%, 0);
}

.ModalContainer--fullScreen {
  height: 100%;
  left: 0;
  max-width: none;
  top: 0;
  width: 100%;
}

.ModalContainer--small {
  border-radius: 0;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40%;

  @media (--viewport-xs) {
    border-radius: var(--space-md);
    height: auto;
    max-height: 85%;
    max-width: 900px;
    width: 80%;
  }

  @media (--viewport-md) {
    border-radius: var(--space-md);
    height: auto;
    max-height: 85%;
    max-width: 900px;
    width: 80%;
  }

  @media (--viewport-lg) {
    border-radius: var(--space-md);
    height: auto;
    max-height: 85%;
    max-width: 900px;
    width: 50%;
  }

  @media (--viewport-xxl) {
    border-radius: var(--space-md);
    height: auto;
    max-height: 85%;
    max-width: 900px;
    width: 35%;
  }
}
