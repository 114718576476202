.Radio {
  cursor: pointer;
  opacity: 0;
  position: absolute;

  + label {
    cursor: pointer;
    display: inline-block;
    margin-left: var(--space-md);
    position: relative;
  }

  + label::before {
    background: var(--color-white);
    border: 2px solid var(--color-neutral-500);
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: var(--space-lg);

    /* Distance from the left of the label in order to replicate margin */
    left: -32px;
    position: absolute;

    /* Distance from the top of the label in order to align with label */
    top: 0;
    transition: transform 0.2s;
    width: var(--space-lg);
  }

  &:checked {
    + label {
      font-weight: var(--font-weight-medium);
    }

    + label::before {
      border: 2px solid var(--color-brandsecondary);
    }

    + label::after {
      background: var(--color-brandsecondary);
      border: 2px solid var(--color-brandsecondary);
      border-radius: 50%;
      content: '';
      display: inline-block;
      height: var(--space-md);

      /* Used to centralise inner checked dot of button */
      left: -28px;
      position: absolute;

      /* Used to centralise inner checked dot of button */
      top: 4px;
      transition: transform 0.2s;
      width: var(--space-md);
    }
  }

  &:focus {
    + label::before {
      box-shadow: 0 0 8px var(--color-brandsecondary-hover);
    }
  }

  &:disabled {
    + label {
      color: var(--color-neutral-400);
      cursor: default;
    }

    + label::before {
      background: var(--color-neutral-200);
      border: 2px solid var(--color-neutral-300);
    }

    + label::after {
      border-radius: 50%;
      content: '';
      display: inline-block;
      height: var(--space-md);

      /* Used to centralise inner checked dot of button */
      left: -28px;
      position: absolute;

      /* Used to centralise inner checked dot of button */
      top: 4px;
      width: var(--space-md);
    }
  }
}

.Radio-helperText {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-light);
}
