.HorizontalRule {
  border: 0;
  margin-bottom: 0;
  margin-top: 0;
  width: 100%;
}

.HorizontalRule--solid {
  background-color: var(--color-neutral-100);
  color: var(--color-neutral-100);
  height: 1px;
}

.HorizontalRule--dashed {
  border-top: 1px dashed var(--color-neutral-100);
}
