.ErrorMessage {
  color: var(--color-error);
  font: var(--font-style-content-2);

  /* Unlike other <p>'s, an ErrorMessage should have its
  spacing controlled from the outside. Consider using
  VerticalSpacing. */
  margin-bottom: 0;

  > a {
    color: inherit;
    text-decoration: underline;
  }
}

.ErrorMessage--centered {
  text-align: center;
}

.ErrorMessage--marginless {
  margin: 0;
}
