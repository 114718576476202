@import '~theme/viewport.module.css';

.MeetingRoomProductList {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.MeetingRoomProductList-body {
  & tr {
    border-bottom: 1px solid var(--color-neutral-100);

    &:first-child {
      border-top: 1px solid var(--color-neutral-100);
    }

    &:last-child {
      border-bottom: none;
    }
  }

  & td {
    &:first-child {
      padding: var(--space-md);
    }

    &:last-child {
      width: 100%;
    }

    padding: var(--space-md) var(--space-md) var(--space-md) 0;
    width: fit-content;
  }
}

.MeetingRoomProductList-button {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.MeetingRoomProductList-capacity {
  white-space: nowrap;
}

.MeetingRoomProductList-header {
  display: none; /* Hide on mobile */

  @media (--viewport-sm) {
    background-color: var(--color-neutral-50);
    display: revert;

    th {
      font-weight: var(--font-weight-light);
      padding: var(--space-sm) 0;
    }
  }
}

.MeetingRoomProductList-headerDaily {
  align-items: center;
  display: flex;
  gap: var(--space-sm);
  width: fit-content;
}

.MeetingRoomProductList-mobilePrices {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font: var(--font-style-content-3);
  margin-top: var(--space-sm);

  @media (--viewport-sm) {
    display: none;
  }
}

.MeetingRoomProductList-priceDetail {
  display: none; /* Hide on mobile */

  @media (--viewport-sm) {
    display: revert;
    padding: var(--space-md);
  }
}
