.OfficeDetail-matchWrapper {
  display: flex;
  font: var(--font-style-content-2);
  justify-content: space-between;
}

.OfficeDetail-desks,
.OfficeDetail-price {
  font: var(--font-style-content-2);
  font-weight: var(--font-weight-medium);
}

.OfficeDetail-days,
.OfficeDetail-alsoAvailable,
.OfficeDetail-alsoAvailablePrice {
  font: var(--font-style-content-3);
}
