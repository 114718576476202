.ImageCarouselExpandButton-button {
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.ImageCarouselExpandButton-icon {
  color: white;
}

.ImageCarouselExpandButton-iconWrapper {
  background-color: rgb(var(--color-black-rgb) / 80%);
  bottom: var(--space-md);
  height: var(--space-lg);
  position: absolute;
  right: var(--space-md);
  width: var(--space-lg);
}
