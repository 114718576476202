.Pagination-list {
  display: flex;
  justify-content: center;
  padding: 0;
  width: 100%;
}

.Pagination-item {
  display: flex;
  list-style: none;
  margin-right: var(--space-sm);

  &:last-child {
    margin-right: 0;
  }
}

.Pagination-ellipsis {
  cursor: default;
  height: var(--space-xl);
  padding-left: var(--space-base);
  width: var(--space-xl);
}

.Pagination-link {
  align-items: center;
  border-radius: 50%;
  color: var(--color-neutral-900);
  display: flex;
  height: var(--space-xl);
  justify-content: center;
  text-decoration: none;
  transition: var(--color-brandsecondary) 0.2s;
  width: var(--space-xl);

  &:active {
    background-color: var(--color-brandsecondary);
    text-decoration: none;
    transition: var(--color-brandsecondary) 0.2s;
  }

  &:visited {
    text-decoration: none;
    transition: var(--color-brandsecondary) 0.2s;
  }

  &:hover {
    background-color: var(--color-brandsecondary-hover);
    text-decoration: none;
    transition: var(--color-brandsecondary-hover) 0.2s;
  }
}

.Pagination-currentPage {
  background-color: var(--color-brandsecondary);
  border-radius: 50%;
  color: var(--color-neutral-500);
}
