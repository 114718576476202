@import '~theme/viewport.module.css';

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes shift-up {
  0% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes shift-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.Drawer {
  background-color: var(--color-white);
  border: none;
  height: 100dvh;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  top: 0;
  width: 100%;

  &[open] {
    animation:
      fade-in 250ms ease,
      shift-up 250ms ease;
  }

  @media (--viewport-md) {
    /* affix drawer to right side of the screen */
    left: auto;
    width: fit-content;

    &::backdrop {
      animation: fade-in 250ms ease;
      background-color: rgb(var(--color-neutral-900-rgb) / 30%);
      overflow: hidden;
    }

    &[open] {
      animation: shift-left 250ms ease;
    }
  }
}

.Drawer-content {
  /* fill the container */
  height: 100%;
}
