.USPContent--trigger {
  color: var(--color-neutral-900);
  cursor: pointer;
  padding: 0;
  text-align: left;
}

.USPContent-discountLabel {
  font-weight: 300;
}
