.Checkbox {
  margin-right: var(--space-sm);
  position: relative;
  text-align: left;
}

.Checkbox--disabled {
  opacity: 0.5;
}

.Checkbox-icon {
  font-size: var(--font-size-lg);
  left: 50%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  input[type='checkbox']:checked + & {
    opacity: 1;
  }
}

.Checkbox-icon--lg {
  font-size: var(--font-size-xxl);
}

.Checkbox-input {
  appearance: none;
  border: solid 1px var(--color-neutral-500);
  border-radius: var(--space-base);
  display: block;
  height: var(--space-lg);
  margin: 0;
  width: var(--space-lg);

  &:not([disabled]):checked {
    background-color: var(--color-brandsecondary);
    border: solid 1px var(--color-brandsecondary);
  }
}

.Checkbox-label {
  color: var(--color-neutral-800);
  font-family: var(--font-family);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-light);
}

.Checkbox-wrapper {
  align-items: center;
  display: flex;

  & * {
    cursor: pointer;
  }
}

.Checkbox-label--hidden {
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  width: 0;
}

.Checkbox-input--lg {
  &:not(:checked) {
    height: 45px;
    width: 45px;
  }

  &:checked {
    height: 45px;
    width: 45px;
  }
}

.Checkbox--alignStart {
  align-items: start;
}
