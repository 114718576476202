.AccountIconImg {
  background-clip: content-box;
  border: 3px solid transparent;
  box-shadow: 0 0 0 transparent;
  margin: calc(var(--space-base) / 2);
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.AccountIconImg-imageContainer {
  background-clip: content-box;
  border: 3px solid transparent;
  border-radius: 50%;
  box-shadow: 0 0 0 transparent;
  height: var(--AccountIconImg-imgSize);
  margin: calc(var(--space-base) / 2);
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  width: var(--AccountIconImg-imgSize);
}

.AccountIconImg-imageContainer--hover {
  &:hover {
    box-shadow: 0 0 0 2px var(--color-brandprimary);
    cursor: pointer;
  }
}

.AccountIconImg-img {
  height: 100%;
  width: 100%;
}
