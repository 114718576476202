@import '~theme/viewport.module.css';

.AdvisorSearchRecommendationLists-control {
  background-color: var(--color-transparent);
  border: 1px solid var(--color-neutral-200);
  border-bottom-right-radius: 4px;
  border-left-color: transparent;
  border-top-right-radius: 4px;
  color: var(--color-neutral-900);
  cursor: pointer;
  height: 48px;
  margin: 0;
  padding: calc(var(--space-base) * 3);
  transition-duration: var(--animation-short);
  transition-property: background-color, border-color, box-shadow, color;
  transition-timing-function: linear;

  &:active,
  &:hover,
  &:focus {
    background-color: var(--color-neutral-900);
    border: 1px solid var(--color-neutral-900);
    border-left-color: var(--color-neutral-900);
    color: var(--color-white);
  }

  &:disabled {
    background: var(--color-neutral-200);
    border: 1px solid var(--color-neutral-200);
    border-left-color: transparent;
    color: var(--color-neutral-500);
  }
}
