.Fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.Fieldset-legend {
  margin-bottom: var(--space-xs);
  padding: 0;
}
