@import '~theme/viewport.module.css';

.SearchMenuContent-onDemandCardWrapper {
  display: grid;
  gap: var(--space-md);
  grid-template-columns: 1fr;

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
  }
}

.SearchMenuContent-rentLongerCardWrapper {
  display: grid;
  gap: var(--space-md);
  grid-template-columns: 1fr;
}

.SearchMenuContent-border {
  @media (--viewport-lg) {
    border-right: 1px solid var(--color-neutral-100);
    padding-right: var(--space-md);
  }
}
