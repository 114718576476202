@import '~theme/viewport.module.css';

.MegaMenu {
  display: none;

  /*
   * Buttons and links inside a menu should always use neutrals, because there
   * will always be a white background.
   */
  --navbar-interactive-color: var(--color-neutral-900);
  --navbar-interactive-color-active: var(--color-neutral-500);
  --navbar-interactive-color-hover: var(--color-neutral-500);

  @media (--viewport-lg) {
    background-color: var(--color-white);
    border-top: 1px solid var(--color-neutral-100);
    box-shadow: var(--shadow-raised);
    left: 0;
    position: absolute;
    right: 0;
    top: 100%;
    width: 100%;
  }
}

.MegaMenu--open {
  display: block;
  overflow: hidden;
}
