@import '~theme/viewport.module.css';

.SimpleMenu {
  display: none;
}

.SimpleMenu--alignLeft {
  @media (--viewport-lg) {
    left: 0;
  }
}

.SimpleMenu--alignRight {
  @media (--viewport-lg) {
    right: 0;
  }
}

.SimpleMenu--open {
  display: block;
  padding: var(--space-sm) var(--space-lg);

  /*
   * Buttons and links inside a menu should always use neutrals, because there
   * will always be a white background.
   */
  --navbar-interactive-color: var(--color-neutral-900);
  --navbar-interactive-color-active: var(--color-neutral-500);
  --navbar-interactive-color-hover: var(--color-neutral-500);

  @media (--viewport-lg) {
    background-color: var(--color-white);
    border: 1px solid var(--color-neutral-100);
    border-radius: var(--space-md);
    box-shadow: var(--shadow-resting);
    padding: var(--space-lg);
    position: absolute;
    right: 0;
    top: 100%;
    width: max-content;
  }
}
