.GhostLoginBanner {
  background-color: var(--color-ghostlogin);
  color: var(--color-neutral-900);
  display: flex;
  justify-content: space-between;
  text-decoration: none;

  &:hover,
  &:focus {
    color: var(--color-neutral-800);
    text-decoration: none;
  }

  &:active {
    color: var(--color-neutral-700);
  }
}

.GhostLoginBanner-content {
  display: flex;
  font: var(--font-style-content-3);
  margin: 0;
  padding: calc(var(--space-base) * 3) var(--space-sm)
    calc(var(--space-base) * 3) var(--space-md);
}

.GhostLoginBanner-logOut {
  color: var(--color-neutral-900);
  cursor: pointer;
  font: var(--font-style-content-3);
  padding: calc(var(--space-base) * 3) var(--space-md)
    calc(var(--space-base) * 3) var(--space-sm);
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

.GhostLoginBanner-icon {
  font-size: 21px;
  margin-right: var(--space-sm);
  margin-top: -2px;
}
