@import '~theme/viewport.module.css';

.SearchWorkspacesForm-label {
  width: 100%;
}

.SearchWorkspacesForm-content {
  align-items: center;
  display: grid;
  grid-gap: 14px;

  @media (--viewport-md) {
    grid-template-columns: auto min-content;
  }
}
