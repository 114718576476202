@import '~theme/viewport.module.css';
@import '~theme/typography.module.css';

.TypeAhead-wrapper {
  border-radius: 28px;
  box-shadow: var(--shadow-subtle);
  display: flex;
  flex-direction: column;
  position: relative;
}

.TypeAhead-inputGroup {
  display: flex;
  height: var(--space-minimumtargetsize);

  @media (--viewport-lg) {
    height: 48px;
  }
}

.TypeAhead-input {
  background: var(--color-white);
  border: 1px solid var(--color-neutral-100);
  border-bottom-left-radius: 28px 28px;
  border-right: none;
  border-top-left-radius: 28px 28px;
  color: var(--color-neutral-900);
  font-family: inherit;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: normal;
  outline: none;
  padding: 0 calc(var(--space-minimumtargetsize) * 2) 0 var(--space-lg);
  text-overflow: ellipsis;
  width: 100%;

  ::placeholder {
    color: var(--color-neutral-500);
    font-weight: var(--font-weight-light);
    text-overflow: ellipsis;
  }
}

.TypeAhead-input--hasResults {
  border-bottom-left-radius: 0;
}

.TypeAhead-clearButton {
  border-bottom: 1px solid var(--color-neutral-100);
  border-top: 1px solid var(--color-neutral-100);
  color: var(--color-neutral-300);
  cursor: pointer;
  height: var(--space-minimumtargetsize);
  object-fit: fill;
  padding: 0;

  &:hover {
    color: var(--color-neutral-400);
  }

  @media (--viewport-lg) {
    height: 48px;
    line-height: 48px;
  }
}

.TypeAhead-icon {
  font-size: var(--font-size-xl);
  height: var(--space-minimumtargetsize);
  line-height: var(--space-minimumtargetsize);
  text-align: center;

  @media (--viewport-lg) {
    top: 2px;
  }
}

.TypeAhead-searchButton {
  background: var(--color-white);
  border: 1px solid var(--color-neutral-100);
  border-bottom-right-radius: 28px 28px;
  border-top-right-radius: 28px 28px;
  composes: material-icon;
  cursor: pointer;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-medium);
  padding: var(--space-sm) var(--space-md);

  &:disabled {
    background: var(--color-white);
    cursor: default;
  }

  &:enabled {
    &:hover {
      background: var(--color-brandprimary-hover);
      color: var(--color-white);
    }

    &:active {
      background: var(--color-brandprimary-active);
      color: var(--color-white);
    }
  }
}

.TypeAhead-searchButton--hasResults {
  border-bottom-right-radius: 0;

  &:enabled {
    background: var(--color-primary);
    color: var(--color-white);
  }
}
