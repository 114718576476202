.DatePicker {
  display: none;
}

.DatePicker,
.is-open {
  background-color: var(--color-white);
  border-radius: var(--space-sm);
  display: block;
  position: absolute;
  z-index: var(--z-index-modal);
}

.DatePicker-dayPicker {
  --rdp-day-height: var(--space-xxl);
  --rdp-day-width: var(--space-xxl);

  border: 1px solid var(--color-neutral-500);
  border-radius: 4px;
  box-shadow: var(--shadow-resting);
  font: var(--font-style-content-2);
  margin: 0;
  padding: var(--space-sm);
}

.DatePicker-chevron {
  color: var(--color-neutral-500);
}

.DatePicker-day {
  &:hover {
    background-color: var(--color-neutral-50);
    border-radius: 50%;
  }
}

.DatePicker-day--disabled {
  > button {
    color: var(--color-neutral-200);
  }

  &:hover {
    background-color: white;
    border: none;
  }
}

.DatePicker-monthCaption {
  color: var(--color-neutral-900);
  font: var(--font-style-title-4);
  margin-bottom: var(--space-sm);
  margin-left: var(--space-sm);
  margin-top: var(--space-sm);
}

.DatePicker-nav {
  align-items: center;
  color: var(--color-neutral-900);
  display: flex;
  height: var(--rdp-nav-height);

  /* A hack to align the month navigation chevron with the month caption */
  padding-bottom: 16px;
  position: absolute;
  right: 0;
  top: 0;
}

.DatePicker-day--selected {
  background-color: var(--color-brandsecondary);
  border-radius: 50%;
  color: var(--color-neutral-900);

  &:hover {
    background-color: var(--color-brandsecondary-hover);
  }
}

.DatePicker-day--today {
  font-weight: var(--font-weight-medium);
}

.DatePicker-weekday {
  color: var(--color-neutral-900);
  font-family: var(--font-family);
  font-size: var(--font-size-xxs);
  text-transform: uppercase;
}
