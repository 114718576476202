@import '~theme/viewport.module.css';

.ProductBookingDetailsWrapper-requestToBook {
  margin-top: var(--space-md);
  text-align: left;
}

.ProductBookingDetailsWrapper-cardHeading {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: var(--space-md);
  justify-content: space-between;
  padding: var(--space-md);

  @media (--viewport-sm) {
    align-items: center;
    flex-direction: row;
    padding: 18px var(--space-lg);
  }
}

.ProductBookingDetailsWrapper-responseTimeWrapper {
  align-items: center;
  display: flex;
  font-size: var(--font-size-xs);
}

.ProductBookingDetailsWrapper-icon {
  font-size: var(--font-size-lg);
  margin-right: var(--space-sm);
}
