@import '~theme/viewport.module.css';

.FacilitiesList {
  columns: 2;

  @media (--viewport-md) {
    columns: 5;
  }

  > li {
    break-inside: avoid;
    margin-bottom: var(--space-md);
  }
}

.FacilitiesList--knockback {
  color: var(--color-neutral-300);
}

.FacilitiesList--passFacilities {
  display: grid;
  grid-gap: var(--space-md);
  grid-template-columns: 1fr 1fr 1fr;
  list-style: none;
  margin: 0;
  margin-bottom: var(--space-xl);
  margin-top: var(--space-md);
  padding: 0;
}
