@import '~theme/viewport.module.css';

.Highlights {
  columns: 1;

  @media (--viewport-md) {
    display: grid;
    grid-gap: var(--space-sm);
    grid-template-columns: 1fr 1fr;
  }
}

.Highlights-highlight {
  break-inside: avoid;
  margin-bottom: var(--space-lg);

  &:nth-last-child() {
    margin-bottom: 0;
  }
}
