.Summary {
  > * p {
    margin-top: 0;
  }
}

.Summary-summary {
  color: var(--color-neutral-900);
  cursor: pointer;
  display: block;
  text-decoration: underline;
  width: 100%;

  &::-webkit-details-marker {
    display: none;
  }

  &:hover,
  &:focus {
    color: var(--color-neutral-800);
  }

  &::after {
    content: ' more';
    display: inline;
  }

  .Summary-details[open] & {
    bottom: 0;
    position: absolute;
  }

  .Summary-details[open] &::after {
    content: ' less';
  }
}

.Summary-details[open] {
  padding-bottom: var(--space-lg);
  position: relative;
}

.Summary-hashtags {
  list-style: none;
  padding: 0;

  > li {
    display: inline;
    margin-right: var(--space-xs);

    &:nth-last-child() {
      margin-right: 0;
    }
  }
}
