@import '~theme/viewport.module.css';

.NavBarSpacerItem {
  display: none;

  @media (--viewport-lg) {
    display: block;
    flex-grow: 1;
  }
}
