@import '~theme/viewport.module.css';

.BookViewingButton-totalIcon {
  background-color: var(--color-brandsecondary);
  border-radius: 50%;
  color: var(--color-neutral-900);
  display: inline-block;
  font-size: var(--font-size-xxs);
  height: var(--space-lg);
  padding: var(--space-xs);
  text-align: center;
  width: var(--space-lg);

  &.isNone {
    background-color: var(--color-neutral-200);
    color: var(--color-neutral-600);
  }
}

.BookViewingButton-drawer {
  padding: var(--space-md);

  @media (--viewport-md) {
    width: 490px;
  }
}
