.CustomDropdown-select {
  appearance: none;
  cursor: pointer;
  display: block;
  font-size: var(--font-size-md);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;

  /**
   * Bump above other relatively-positioned adjacent elements (ie.
   * displayComponent, if it has any CSS properties set that would normally
   * position it above an absolutely-positioned adjacent element)
   */
  z-index: 1;
}

.CustomDropdown-wrapper {
  position: relative;
}
