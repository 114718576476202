@import '~theme/viewport.module.css';

.ImageCarousel-topAccessories {
  display: flex;
  justify-content: space-evenly;
  max-width: 100%;
  padding: var(--space-md) var(--space-sm);
  position: absolute;
  right: var(--space-base);
  text-align: center;
  top: 0;
}

.ImageCarousel-bottomAccessories {
  align-items: flex-start;
  background-color: var(--color-white);
  box-shadow: var(--shadow-resting);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: var(--space-sm) 0;
  width: 100%;
}

.ImageCarousel-bottomAccessories--rounded {
  @media (--viewport-lg) {
    border-bottom-left-radius: var(--space-md);
    border-bottom-right-radius: var(--space-md);
  }
}

.ImageCarousel--fullScreen {
  height: 67.5%;
  width: 100%;
}

.ImageCarousel--showButtonsOnHover {
  @media (hover: hover) {
    button {
      display: none;
    }

    &:hover button {
      display: flex;
    }
  }
}

.ImageCarousel-sizer {
  /* 4:3 ratio at mobile */
  padding-bottom: calc(100% * (3 / 4));
  position: relative;

  @media (--viewport-md) {
    /* 3:2 ratio at desktop */
    padding-bottom: calc(100% * (2 / 3));
  }
}

.ImageCarousel-sizer--compact {
  /* 2:1 ratio at mobile */
  padding-bottom: calc(100% * (1 / 2));
  position: relative;

  @media (--viewport-md) {
    /* 2:1 ratio at mobile */
    padding-bottom: calc(100% * (1 / 2));
  }
}

.ImageCarousel-sizer--fullScreen {
  height: 100%;
  padding-bottom: 0;

  @media (--viewport-md) {
    padding-bottom: 0;
  }
}

.ImageCarousel-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.ImageCarousel-wrapper-rounded {
  @media (--viewport-md) {
    border-radius: var(--space-md);
  }
}

.ImageCarousel-wrapper--roundedWithBottomAccessories {
  @media (--viewport-lg) {
    border-top-left-radius: var(--space-md);
    border-top-right-radius: var(--space-md);
  }
}

.ImageCarousel-wrapper--fullScreen {
  @media (--viewport-md) {
    left: 50%;
    transform: translateX(-50%);
    width: 67.5%;
  }
}

.ImageCarousel--overrideAspectRatioStyleMobile {
  /* ratio at mobile
  */
  padding-bottom: calc(
    100% *
      (var(--ImageCarousel-mobileHeight) / var(--ImageCarousel-mobileWidth))
  );
  position: relative;
}

.ImageCarousel--overrideAspectRatioStyleDesktop {
  @media (--viewport-md) {
    /* ratio at desktop */
    padding-bottom: calc(
      100% *
        (var(--ImageCarousel-desktopHeight) / var(--ImageCarousel-desktopWidth))
    );
  }
}
