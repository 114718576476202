@import '~theme/viewport.module.css';

.PageNavigationItem {
  align-items: center;
  border-bottom: var(--space-xs) solid transparent;
  border-top: var(--space-xs) solid transparent;
  color: var(--color-neutral-900);
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-xs);
  height: var(--space-xxl);
  margin: 0 var(--space-md);

  &:hover,
  :focus {
    color: var(--color-neutral-800);
    text-decoration: none;
  }

  &:active {
    color: var(--color-neutral-700);
  }

  @media (--viewport-md) {
    font-size: var(--font-size-sm);
    height: calc(var(--space-base) * 16);
  }
}

.PageNavigationItem--active {
  border-bottom-color: var(--color-brandprimary);
  color: var(--color-brandprimary);
}
