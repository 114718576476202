.PassProducts-list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.PassProducts-productItem {
  list-style: none;
  margin-bottom: var(--space-md);
}
