@import '~theme/viewport.module.css';

.HamburgerButton {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: var(--space-md);
  outline: none;
  padding: 0;
  transition: color 0.3s ease;

  & span {
    background: currentcolor;
    border-radius: var(--space-sm);
    display: block;
    height: 3px;
    margin: var(--space-base) 0 var(--space-base) 0;
    transform-origin: 0%;
    transition:
      opacity 0.3s ease,
      transform 0.3s ease;
    width: var(--space-lg);
  }

  @media (--viewport-lg) {
    display: none;
  }
}

.HamburgerButton--open {
  & span {
    &:nth-child(1) {
      transform: rotate(45deg) translateY(-75%);
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:nth-child(3) {
      transform: rotate(-45deg) translateY(75%);
    }
  }
}
