.ViewingRequestBanner {
  background: var(--color-white);
  border-top: 1px solid var(--color-neutral-200);
  padding: var(--space-md) 0;
}

.ViewingRequestBanner-content {
  align-items: center;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}

.ViewingRequestBanner-officeDetails {
  display: flex;
  flex-direction: column;
}

.ViewingRequestBanner-actions {
  display: flex;
  gap: var(--space-sm);
}
