@import '~theme/viewport.module.css';

.DropdownPricePlan-hideInMobile {
  display: none;

  @media (--viewport-md) {
    display: block;
  }
}

.DropdownPricePlan-wrapper {
  flex: 1;
}
