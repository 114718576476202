@import '~theme/viewport.module.css';

.NavBarCustomItem {
  display: block;
  white-space: nowrap;

  @media (--viewport-lg) {
    align-items: center;
    display: flex;
  }
}
