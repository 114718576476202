@import '../../../theme/typography.module.css';

.ListItemWithIcon {
  composes: material-icon-pseudoelement--before;
  margin-bottom: var(--space-xs);
  padding-left: var(--space-lg);
  position: relative;

  &::before {
    color: var(--ListItemWithIcon-iconColor);
    content: var(--ListItemWithIcon-iconContent);
    float: left;
    left: 0;
    position: absolute;
  }
}

.ListItemWithIcon--content1::before {
  font-size: var(--font-size-lg);
  top: 3px;
}

.ListItemWithIcon--content2::before {
  font-size: var(--font-size-md);
  top: 2px;
}

.ListItemWithIcon--content3::before {
  font-size: var(--font-size-sm);
  top: 1px;
}

.ListItemWithIcon--hasBubbleBackground::before {
  align-items: center;
  background-color: var(--ListItemWithIcon-bubbleColor);
  border: 2px solid var(--ListItemWithIcon-bubbleColor);
  border-radius: 50%;
  display: flex;
  font-size: 23px;
  height: 31px;
  justify-content: center;
  padding: var(--space-xs);
  width: 31px;
}

.ListItemWithIcon--hasHeading {
  margin-bottom: var(--space-lg);
  padding-left: var(--space-xxl);

  &::before {
    font-size: var(--font-size-xl);
    top: -2px;
  }
}

.ListItemWithIcon--isNumbered {
  margin-bottom: var(--space-lg);
  padding-left: var(--space-xxl);

  &::before {
    border: 2px solid black;
    border-radius: 50%;
    font-family: var(--font-family);
    font-size: var(--font-size-md);
    height: 31px;
    padding: var(--space-xs);
    text-align: center;
    width: 31px;
  }
}

.ListItemWithIcon-paragraph {
  margin: 0;
}

.ListItemWithIcon-paragraph--content1 {
  font: var(--font-style-content-1);
}

.ListItemWithIcon-paragraph--content2 {
  font: var(--font-style-content-2);
}

.ListItemWithIcon-paragraph--content3 {
  font: var(--font-style-content-3);
}
