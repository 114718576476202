@import '~theme/viewport.module.css';

.DisplayComponent-capacity {
  display: none;
  font: var(--font-style-content-3);
  grid-area: capacity;
  margin-right: var(--space-md);
  @media (--viewport-lg) {
    display: block;
  }
}

.DisplayComponent-availableFrom {
  color: var(--color-neutral-500);
  display: none;
  grid-area: availability;
  text-transform: uppercase;

  @media (--viewport-lg) {
    display: block;
  }
}

.DisplayComponent-availableFrom--now {
  color: var(--color-neutral-900);
}

.DisplayComponent {
  align-items: center;
  border: 1px solid var(--color-neutral-200);
  border-radius: var(--space-xs);
  display: grid;
  grid-template-areas: 'detail carat';
  grid-template-columns: 1fr auto;
  justify-items: stretch;
  padding: var(--space-sm);
  width: 100%;

  @media (--viewport-lg) {
    grid-template-areas:
      'detail detail carat'
      'capacity availability carat';
    grid-template-columns: auto 1fr auto;
  }
}

.DisplayComponent-displayContent {
  display: flex;
  flex-direction: column;
  font: var(--font-style-content-3);
  grid-area: detail;

  span:first-child {
    font-weight: var(--font-weight-medium);
  }

  span:nth-child(2) {
    display: none;
  }

  span:last-child {
    color: var(--color-neutral-500);
  }

  @media (--viewport-lg) {
    flex-direction: row;
    font-weight: var(--font-weight-medium);
    > span:nth-child(2) {
      display: block;
    }

    span:last-child {
      color: var(--color-neutral-900);
    }
  }
}

.DisplayComponent-dropdownTitle {
  color: var(--color-neutral-900);
  display: block;
  font: var(--font-style-content-2);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  margin: 0;
  padding: 0;

  @media (--viewport-lg) {
    display: inline;
    font-size: var(--font-size-sm);
  }
}

.DisplayComponent-carat {
  grid-area: carat;
}
