@import '~theme/viewport.module.css';

.BookingSummary-image {
  border-radius: var(--space-md);
  width: 100%;
}

.BookingSummary-dataList {
  font: var(--font-style-content-2);
  margin-bottom: 0;

  & > div {
    align-items: start;
    display: flex;
    justify-content: space-between;

    @media (--viewport-md) {
      border-bottom: 1px solid var(--color-neutral-200);
      padding: var(--space-md) 0;
    }

    &:last-of-type {
      border-bottom: none;
      padding: var(--space-md) 0 0;
    }

    /* Only show the first data point on mobile */
    &:not(:first-child) {
      display: none;

      @media (--viewport-md) {
        display: flex;
      }
    }
  }
}

.BookingSummary-description {
  text-align: right;
}

.DropdownSelector-askAdminLabel {
  align-items: center;
  color: var(--color-neutral-700);
  display: flex;
  justify-content: center;
}
